import { createTheme, TypographyVariantsOptions } from "@mui/material";
import { CSSProperties } from "react";
import palette from "./palette";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    navHeading: CSSProperties;
    navText: CSSProperties;
    display: CSSProperties;
    caption: CSSProperties;
    headerActive: CSSProperties;
    headerInactive: CSSProperties;
    h5: CSSProperties;
    h6: CSSProperties;
    body3: CSSProperties;
    body4: CSSProperties;
    body5: CSSProperties;
    body6: CSSProperties;
    body7: CSSProperties;
  }

  interface TypographyVariantsOptions {
    navHeading?: CSSProperties;
    navText?: CSSProperties;
    display?: CSSProperties;
    caption?: CSSProperties;
    headerActive?: CSSProperties;
    headerInactive?: CSSProperties;
    h5: CSSProperties;
    h6: CSSProperties;
    body3?: CSSProperties;
    body4?: CSSProperties;
    body5?: CSSProperties;
    body6?: CSSProperties;
    body7?: CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    navHeading: true;
    navText: true;
    display: true;
    headerActive: true;
    headerInactive: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
  }
}

const primaryFont = "Roboto";
const secondaryFont = "Euclid Flex";
const displayFont = "degular-display";

const basePrimaryText: CSSProperties = {
  fontFamily: primaryFont,
  fontWeight: 500,
  fontSize: "0.938rem",
};

const baseSecondaryText: CSSProperties = {
  fontFamily: secondaryFont,
  fontWeight: 500,
};

const baseDisplayText: CSSProperties = {
  fontFamily: displayFont,
  fontWeight: 500,
};

const theme = createTheme({ palette });
export const typography: TypographyVariantsOptions = {
  h1: {
    ...baseDisplayText,
    fontSize: "3rem",
    color: theme.palette.secondary.main,
  },
  h2: {
    fontFamily: secondaryFont,
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  h3: {
    fontFamily: primaryFont,
    fontWeight: 700,
    fontSize: "0.875rem",
  },
  h4: {
    ...baseSecondaryText,
    fontSize: "1.5rem",
  },
  h5: {
    ...basePrimaryText,
    fontSize: "0.688rem",
  },
  h6: {
    ...baseSecondaryText,
    fontSize: "2.75rem",
    lineHeight: "2.75rem",
    fontWeight: 300,
  },

  body1: {
    fontFamily: primaryFont,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.75rem",
  },
  body2: {
    ...basePrimaryText,
    fontWeight: 400,
    fontSize: "0.875rem",
  },
  body3: {
    ...basePrimaryText,
    fontWeight: 400,
  },
  body4: {
    ...baseSecondaryText,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.75rem",
  },
  body5: {
    ...basePrimaryText,
  },
  body6: {
    ...baseSecondaryText,
    fontSize: "1.5rem",
  },
  body7: {
    ...baseSecondaryText,
  },

  headerActive: {
    ...basePrimaryText,
    color: theme.palette.common.black,
  },
  headerInactive: {
    ...basePrimaryText,
    color: palette.hint,
  },

  caption: {
    ...basePrimaryText,
    fontSize: "0.65rem",
    fontWeight: 400,
  },

  navHeading: {
    fontFamily: secondaryFont,
    fontWeight: 500,
    fontSize: "1rem",
  },
  navText: {
    fontFamily: primaryFont,
    fontWeight: 400,
    fontSize: "0.875rem",
  },
  display: {
    ...baseDisplayText,
    fontSize: "2.5rem",
  },
};
