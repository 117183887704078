import React from "react";
import { Box, CSSObject, Stack, Switch, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import { TickCircle } from "../Icons/TickCircle/TickCircle";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Team } from "../../store/slices/interface/interface";

interface Descendant {
  id: string;
  name: string;
  disabled: number;
}

interface UserSettingsTeamsProps {
  teamsTitle?: string;
  teamsLogo?: string;
  editable?: boolean;
  setInitialTeams?: boolean;
  teamsData: Team[] | null;
  selectedIds: string[] | undefined;
  onSelectionChange?: (selectedIds: string[]) => void;
}

const TeamHeader: React.FC<{
  name: string;
  disabled: number;
  id: string;
  editable: boolean;
  selectedIds: string[];
  handleToggle: (id: string) => void;
}> = ({ name, disabled, id, editable, selectedIds, handleToggle }) => (
  <Grid container alignItems="center" justifyContent="space-between">
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="h3" component="h3">
        {name}
      </Typography>
    </Box>
    {editable ? (
      <Switch
        checked={selectedIds.includes(id)}
        onChange={() => handleToggle(id)}
      />
    ) : (
      <TickCircle color={disabled === 0 ? "accent.green" : "accent.cyan"} />
    )}
  </Grid>
);

const DescendantList: React.FC<{
  descendants: Descendant[];
  editable: boolean;
  selectedIds: string[];
  handleToggle: (id: string) => void;
}> = ({ descendants, editable, selectedIds, handleToggle }) => (
  <Box sx={{ display: "flex", alignItems: "top" }}>
    <SubdirectoryArrowRightIcon
      sx={{ color: "gray.dark", paddingTop: editable ? "5px" : 0 }}
    />
    <Stack sx={{ width: "100%" }}>
      {descendants.map((descendant) => (
        <Grid
          key={descendant.id}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="h4">{descendant.name}</Typography>
          {editable ? (
            <Switch
              checked={selectedIds.includes(descendant.id)}
              onChange={() => handleToggle(descendant.id)}
            />
          ) : (
            <TickCircle
              color={descendant.disabled === 0 ? "accent.green" : "accent.cyan"}
            />
          )}
        </Grid>
      ))}
    </Stack>
  </Box>
);

const TeamItem: React.FC<{
  item: Team;
  editable: boolean;
  selectedIds: string[] | undefined;
  handleToggle: (id: string) => void;
  gridStyles: CSSObject;
}> = ({ item, editable, selectedIds, handleToggle, gridStyles }) => (
  <Grid sx={{ ...gridStyles, maxWidth: 500 }}>
    <TeamHeader
      name={item.name}
      disabled={item.disabled}
      id={item.id}
      editable={editable}
      selectedIds={selectedIds || [""]}
      handleToggle={handleToggle}
    />
    {item.descendants.length > 0 && (
      <DescendantList
        descendants={item.descendants}
        editable={editable}
        selectedIds={selectedIds || [""]}
        handleToggle={handleToggle}
      />
    )}
  </Grid>
);

const UserTeams: React.FC<UserSettingsTeamsProps> = ({
  teamsData,
  teamsTitle,
  teamsLogo,
  editable = false,
  selectedIds,
  onSelectionChange,
}) => {
  const theme = useTheme();
  const { grid } = theme.custom.teamsSettings;

  const handleToggle = (id: string) => {
    if (onSelectionChange) {
      const updatedSelection = (selectedIds ?? []).includes(id)
        ? (selectedIds ?? []).filter((item) => item !== id)
        : [...(selectedIds ?? []), id];

      onSelectionChange(updatedSelection);
    }
  };

  if (teamsData) {
    const filteredTeams = teamsData.filter(
      (team) =>
        !teamsData.some((other) =>
          other.descendants.some((descendant) => descendant.id === team.id),
        ),
    );
    return (
      <>
        <Stack direction="row" alignItems="center" spacing={1}>
          {teamsLogo && <img src={teamsLogo} height="30px" alt="Team Logo" />}
          {teamsTitle && (
            <Typography variant="h4" component="h4">
              {teamsTitle}
            </Typography>
          )}
        </Stack>
        {filteredTeams.length > 0 ? (
          filteredTeams.map((item) => (
            <TeamItem
              key={item.id}
              item={item}
              editable={editable}
              selectedIds={selectedIds}
              handleToggle={handleToggle}
              gridStyles={grid}
            />
          ))
        ) : (
          <Typography variant="body1" color="error">
            No teams data was loaded, or you&apos;re not a part of a team.
          </Typography>
        )}
      </>
    );
  }
  return null;
};

export default UserTeams;
