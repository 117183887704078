import React from "react";
import { ObjectFieldTemplateProps } from "@rjsf/utils";

interface Section {
  title: string;
  fields: string[];
}

const Sections: React.FC<{
  sections: Section[];
  props: ObjectFieldTemplateProps;
}> = ({ sections, props }) => {
  return (
    <>
      {sections.map((section, index) => {
        if (typeof section === "object") {
          const { formContext } = props;
          const SectionTemplate = formContext["sectionTemplate"];
          const childProperties = props.properties.filter((p) =>
            section.fields.includes(p.name),
          );
          return (
            <SectionTemplate
              {...props}
              key={section["title"] + "_" + index}
              title={section["title"]}
              properties={childProperties}
            />
          );
        }
        throw new Error(
          "Invalid section type: " + typeof section + " " + section,
        );
      })}
    </>
  );
};

export default Sections;
