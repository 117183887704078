import React, { useState } from "react";

// MUI Components
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

// Custom Components
import ProvideButton from "../Buttons/ProvideButton";
import UserContactFields from "../UserContactFields/UserContactFields";

// Redux
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { setAddUserData } from "../../store/slices/addUserSlice";
import { RootState } from "../../store/store";
import { UserData } from "../../store/slices/interface/interface";

// Component Props Interface
interface AddUserContactDetailsProps {
  onStep?: (step: string) => void;
}

const AddUserContactDetails: React.FC<AddUserContactDetailsProps> = ({
  onStep,
}) => {
  const dispatch = useAppDispatch();

  // Local State
  const [areInputsValidated, setAreInputsValidated] = useState<boolean>(false);

  // Redux State
  const userDataRedux = useAppSelector(
    (state: RootState) => state.addUser.addUserData,
  );

  // Handle form data updates
  const handleEditedData = (data: UserData) => {
    const { first_name, last_name, email, phone_number, date_of_birth } = data;
    dispatch(
      setAddUserData({
        first_name,
        last_name,
        email,
        phone_number,
        date_of_birth,
      }),
    );
  };

  // Handle continue button click
  const handleContinue = () => {
    if (onStep) onStep("companyRoles");
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      {/* Section Title */}
      <Typography variant="h4" component="h4">
        1. Contact Details
      </Typography>

      {/* User Contact Form */}
      <UserContactFields
        formData={userDataRedux}
        emailDisabled={false}
        editedData={handleEditedData}
        isValidated={setAreInputsValidated}
      />

      {/* Action Buttons */}
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProvideButton
            onClick={handleContinue}
            disabled={!areInputsValidated}
            variant="contained"
          >
            CONTINUE
            <ArrowForwardRoundedIcon sx={{ ml: 1 }} />
          </ProvideButton>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AddUserContactDetails;
