import { AxiosError } from "axios";

const useAxiosErrorMessages = (errors: Array<AxiosError | null>): string[] => {
  return errors
    .filter((error): error is AxiosError => error !== null)
    .map((error) => {
      if (
        error &&
        error.response?.data &&
        typeof error.response.data === "object" &&
        "message" in error.response.data &&
        typeof error.response.data.message === "string"
      ) {
        return error.response.data.message;
      }
      return "An unknown error occurred";
    });
};

export default useAxiosErrorMessages;
