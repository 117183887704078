import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Divider,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ProvideButton from "../Buttons/ProvideButton";
import DismissibleBox from "../DismissibleBox/DismissibleBox";
import ModalContainer from "../Modal/ModalContainer";
import VerifyCodeDialogue from "../Modal/VerifyCodeDialogue";
import useUserValidateInput from "../../hooks/useUserValidateInput";
import useSendOTPCode from "../../hooks/api/useSendOTPCode";
import useAuthenticateOTPCode from "../../hooks/api/useAuthenticateOTPCode";
import useLogin from "../../hooks/api/useLogin";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { clearRedirectPath, login } from "../../store/slices/authSlice";
import useForgotPassword from "../../hooks/api/useForgotPassword";
import { RootState } from "../../store/store";

interface PasswordInputProps {
  emailOrPhone: string;
  onBack: () => void;
}

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const { emailOrPhone, onBack } = props;

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>(false);

  const { validateField } = useUserValidateInput();
  const { handleSendOTPCode, linkError } = useSendOTPCode();
  const { handleAuthenticateOTPCode, OTPError } = useAuthenticateOTPCode();
  const { handleLogin, loggingIn, loginError } = useLogin();
  const { handleForgotPassword, sendingPassword, sendError, sendData } =
    useForgotPassword();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const redirectPath = useAppSelector(
    (state: RootState) => state.auth.redirectPath,
  );

  const isLoginDisabled = !password.trim();

  useEffect(() => {
    const emailValid = validateField("email", emailOrPhone);
    setIsEmailValid(emailValid);
  }, [emailOrPhone]);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
    if (!isModalOpen) {
      sendOTPCode();
    }
  };

  const sendOTPCode = async () => {
    const result = await handleSendOTPCode(emailOrPhone);

    if (result.success) {
      setDataLoaded(true);
    }
  };

  const dispatchThenNavigate = () => {
    dispatch(login());
    navigate(redirectPath || "/dashboard");
    dispatch(clearRedirectPath());
  };

  const onSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    const email = await handleLogin(emailOrPhone, password);

    if (email?.success) {
      dispatchThenNavigate();
    }
  };

  const handleReset = async () => {
    await handleForgotPassword(emailOrPhone);
  };

  const onEnterOTPCode = async (code: string) => {
    const otp = await handleAuthenticateOTPCode(code);

    if (otp?.success) {
      dispatchThenNavigate();
    }
  };

  return (
    <Grid container spacing={3}>
      <Typography variant="h4" component="h4">
        Password
      </Typography>

      <Grid size={12}>
        <DismissibleBox emailOrPhone={emailOrPhone} onBack={onBack} />
      </Grid>

      <Grid size={12}>
        <form onSubmit={onSubmit}>
          <Stack spacing={3}>
            <FormControl variant="outlined">
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={password}
                autoComplete="password"
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Password"
              />
            </FormControl>

            <Grid size={{ xs: 12, md: 8 }}>
              <ProvideButton
                submitbtn="true"
                variant="contained"
                disabled={isLoginDisabled || loggingIn}
              >
                {loggingIn ? "Logging in..." : "LOGIN"}
              </ProvideButton>
            </Grid>

            {loginError && (
              <Typography color="error">
                {loginError.response?.data.message}
              </Typography>
            )}
          </Stack>
        </form>
      </Grid>

      <Grid size={12}>
        <Divider />
      </Grid>
      <Stack spacing={2} width="100%">
        <Typography variant="body2">
          Forgotten your password / Use One-time Password
        </Typography>
        <ProvideButton
          onClick={toggleModal}
          variant="outlined"
          disabled={!isEmailValid}
          sx={{ width: { xs: "100%", md: 308 } }}
        >
          EMAIL LOGIN LINK
        </ProvideButton>
        {sendError && !sendData && (
          <Typography color="error">
            {sendError.response?.data.message}
          </Typography>
        )}
        {sendData && !sendError && (
          <Typography color="green">{sendData.status}</Typography>
        )}
        <ProvideButton
          onClick={handleReset}
          variant="outlined"
          disabled={!isEmailValid || sendingPassword}
          sx={{ width: { xs: "100%", md: 308 } }}
        >
          {sendingPassword ? "Resetting password..." : "RESET PASSWORD"}
        </ProvideButton>
      </Stack>
      <ModalContainer isOpen={isModalOpen}>
        <VerifyCodeDialogue
          title={dataLoaded ? "Link Sent" : "Sending Login Link to:"}
          description={emailOrPhone}
          onConfirm={onEnterOTPCode}
          onClose={toggleModal}
          numberOfBoxes={6}
          statusMessage={OTPError}
          otpMessage={linkError}
        />
      </ModalContainer>
    </Grid>
  );
};

export default PasswordInput;
