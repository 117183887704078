import React from "react";
import { TitleFieldProps } from "@rjsf/utils";
import { Typography } from "@mui/material";

const TitleFieldTemplate: React.FC<TitleFieldProps> = ({ title }) => {
  return (
    <Typography component="p" variant="body7">
      {title}
    </Typography>
  );
};

export default TitleFieldTemplate;
