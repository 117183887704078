import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role, CompanyData, UserData, Team } from "./interface/interface";

interface AddUserState {
  isValidated: boolean;
  userRolesData: Role[] | null;
  companiesData: CompanyData[] | null;
  addUserData: UserData | null;
  selectedCompanyId: string | null;
  teamsData: Team[] | null;
}

const defaultUserViewData = {
  first_name: "",
  last_name: "",
  date_of_birth: "",
  phone_number: "",
  email: "",
};

const initialState: AddUserState = {
  isValidated: false,
  userRolesData: null,
  companiesData: null,
  selectedCompanyId: null,
  addUserData: defaultUserViewData,
  teamsData: null,
};

const addUserSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    setRolesData: (state, action: PayloadAction<Role[]>) => {
      state.userRolesData = action.payload;
    },
    setCompaniesData: (state, action: PayloadAction<CompanyData[]>) => {
      state.companiesData = action.payload;
    },
    setAddUserData: (state, action: PayloadAction<UserData>) => {
      state.addUserData = { ...state.addUserData, ...action.payload };
    },
    setSelectedCompanyId: (state, action: PayloadAction<string | null>) => {
      state.selectedCompanyId = action.payload;
    },
    setTeamsData: (state, action: PayloadAction<Team[]>) => {
      state.teamsData = action.payload;
    },
    setIsValidated: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
  },
});

export const {
  setRolesData,
  setCompaniesData,
  setAddUserData,
  setIsValidated,
  setSelectedCompanyId,
} = addUserSlice.actions;

export default addUserSlice.reducer;
