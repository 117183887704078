import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CardWrapper from "../CardWrapper/CardWrapper";
import ProvideSwitch from "../Switches/ProvideSwitchButton";

export interface ToggleConfig {
  id: string;
  label: string;
  checked: boolean;
}

export interface LiveInfoCardProps {
  createdText?: string;
  lastActiveText?: string;
  toggles: ToggleConfig[];
  onToggleChange: (id: string, checked: boolean) => void;
}

const LiveInfoCard: React.FC<LiveInfoCardProps> = ({
  createdText,
  lastActiveText,
  toggles,
  onToggleChange,
}) => {
  const theme = useTheme();
  const { lightGreen } = theme.palette;

  const handleToggleChange = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onToggleChange(id, event.target.checked);
  };

  return (
    <CardWrapper maxWidth="352px">
      <Box display="flex" alignItems="center" gap={1}>
        <InfoIcon sx={{ color: "lightGreen", fontSize: "1.79rem" }} />
        <Typography variant="h2">Live Info</Typography>
      </Box>

      <Stack>
        <Box display="flex">
          <Typography fontWeight={700}>Created:</Typography>
          <Typography fontWeight={400} sx={{ color: "text.secondary", pl: 1 }}>
            {createdText}
          </Typography>
        </Box>

        <Box display="flex">
          <Typography fontWeight={700}>Last Active:</Typography>
          <Typography fontWeight={400} sx={{ color: "text.secondary", pl: 1 }}>
            {lastActiveText}
          </Typography>
        </Box>
      </Stack>

      <Stack gap={2}>
        {toggles.map((toggle) => (
          <Box key={toggle.id} display="flex" alignItems="center" gap={1}>
            <ProvideSwitch
              size="medium"
              checked={toggle.checked}
              onChange={(event) => handleToggleChange(toggle.id, event)}
              sx={{ "--switch-track-color": lightGreen }}
            />
            <Typography fontWeight={700}>{toggle.label}</Typography>
          </Box>
        ))}
      </Stack>
    </CardWrapper>
  );
};

export default LiveInfoCard;
