import React from "react";
import {
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";

interface LendingOptionFields {
  lendingOptionName: string;
  summary: string;
  branchTeam: string;
  internalProduct: string;
}

interface LendingOptionSummaryProps {
  title: string;
  fields: LendingOptionFields;
  onChange: (field: keyof LendingOptionFields, value: string) => void;
  onSkip: () => void;
}

const LendingOptionSummary: React.FC<LendingOptionSummaryProps> = ({
  title,
  fields,
  onChange,
  onSkip,
}) => {
  return (
    <Stack
      spacing={3}
      sx={{
        maxWidth: "640px",
        padding: 5,
        borderRadius: 1,
        backgroundColor: "#F7F6FB",
      }}
    >
      <Typography variant="h1" sx={{ fontSize: "2rem", color: "black" }}>
        {title}
      </Typography>

      <FormControl fullWidth variant="outlined" margin="normal">
        <OutlinedInput
          placeholder="Lending Option Name"
          value={fields.lendingOptionName}
          onChange={(e) => onChange("lendingOptionName", e.target.value)}
        />
      </FormControl>

      <FormControl fullWidth variant="outlined" margin="normal">
        <OutlinedInput
          placeholder="Summarise this Lending Option"
          value={fields.summary}
          onChange={(e) => onChange("summary", e.target.value)}
          multiline
          minRows={3}
        />
      </FormControl>
      <Stack
        spacing={2}
        justifyContent="space-between"
        direction={{ xs: "column", sm: "row" }}
        sx={{ flex: 1, marginY: 2 }}
      >
        <FormControl fullWidth variant="outlined">
          <Select
            displayEmpty
            value={fields.branchTeam}
            onChange={(e) => onChange("branchTeam", e.target.value as string)}
          >
            <MenuItem value="" disabled>
              Branch / Team (optional)
            </MenuItem>
            <MenuItem value="Branch A">Branch A</MenuItem>
            <MenuItem value="Branch B">Branch B</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            placeholder="Relevant Internal Product (optional)"
            value={fields.internalProduct}
            onChange={(e) => onChange("internalProduct", e.target.value)}
          />
        </FormControl>
      </Stack>

      <Box display="flex" justifyContent="flex-start">
        <Button
          variant="contained"
          startIcon={<Refresh />}
          sx={{ width: { xs: "100%", md: "48%" } }}
          onClick={onSkip}
        >
          SKIP FOR NOW
        </Button>
      </Box>
    </Stack>
  );
};

export default LendingOptionSummary;
