import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SaveIcon from "@mui/icons-material/Save";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProvideButton from "../../components/Buttons/ProvideButton";
import ProvideSwitch from "../../components/Switches/ProvideSwitchButton";
import FilterIcon from "../../components/Icons/FilterIcon/FilterIcon";

const CustomToolbar: React.FC = () => (
  <GridToolbarContainer sx={{ py: 2 }}>
    <Box display="flex" alignItems="center" gap={2}>
      <Box display="flex" alignItems="center" flexDirection="row">
        <IconButton>
          <FilterIcon />
        </IconButton>
        <Typography variant="body1">Filters</Typography>
      </Box>
      <ProvideButton
        buttontype="notification"
        sx={{
          width: 90,
          height: 28,
        }}
      >
        + ROLES
      </ProvideButton>
      <Box display="flex" alignItems="center" gap={1} flexDirection="row">
        <ProvideSwitch size="small" />
        <Typography
          variant="body1"
          sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
        >
          Alerts Only
        </Typography>
      </Box>
    </Box>
    <Box sx={{ flexGrow: 1 }} />
    <Box display="flex" alignItems="center" gap={1}>
      <ProvideButton
        variant="outlined"
        sx={{
          width: 135,
          height: 28,
        }}
      >
        <Box display="flex" alignItems="center" gap={1} flexDirection="row">
          <SaveIcon sx={{ fontSize: "1.2rem" }} />
          <Typography>SAVE VIEW</Typography>
        </Box>
      </ProvideButton>
      <ProvideButton
        variant="outlined"
        sx={{
          width: 135,
          height: 28,
        }}
      >
        <Box display="flex" alignItems="center" gap={1} flexDirection="row">
          <FilterAltOffIcon sx={{ fontSize: "1.2rem" }} />
          <Typography>CLEAR ALL</Typography>
        </Box>
      </ProvideButton>
      <IconButton>
        <MoreVertIcon sx={{ color: "black" }} />
      </IconButton>
    </Box>
  </GridToolbarContainer>
);

export default CustomToolbar;
