export interface UserPillItem {
  id: number;
  label: string;
  option: string;
}

export const userPillData: UserPillItem[] = [
  { id: 1, label: "MAIN DETAILS", option: "main" },
  { id: 2, label: "RECENT ACTIVITIES", option: "activities" },
  { id: 3, label: "TEAM MEMBERSHIPS", option: "teams" },
  { id: 4, label: "ENQUIRIES", option: "enquiries" },
  { id: 5, label: "DOCUMENTS", option: "documents" },
  { id: 6, label: "NOTIFICATION SETTINGS", option: "notifications" },
];
