import React from "react";
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import Sections from "./Sections";
import { useSchemaFormTemplate } from "../../../hooks/useSchemaFormTemplate";

const DefaultObjectFieldTemplate = useSchemaFormTemplate("ObjectFieldTemplate");

const SectionsContainer: React.FC<ObjectFieldTemplateProps> = (props) => {
  const { uiSchema, idSchema } = props;

  if (!uiSchema || idSchema.$id !== "root") {
    return <DefaultObjectFieldTemplate {...props} />;
  }

  return <Sections sections={uiSchema["ui:sections"]} props={props}></Sections>;
};

export default SectionsContainer;
