import { CSSObject } from "@mui/system";
import { createTheme, Theme } from "@mui/material";
import palette from "./palette";

declare module "@mui/material/styles" {
  interface Theme {
    custom: CustomTheme;
  }

  interface ThemeOptions {
    custom?: CustomTheme;
  }
}

export interface CustomTheme {
  contentWidth: {
    menuClosedWidth: string;
    menuOpenWidth: string;
  };
  iconButton: CSSObject;
  customIconButton: CSSObject;
  plainTextButton: CSSObject;
  saveChangesBox: CSSObject;
  notificationAvatar: CSSObject;
  loginWave: CSSObject;
  sideNavigation: {
    container: CSSObject;
    button: CSSObject;
  };
  pillNavigation: {
    container: CSSObject;
    button: CSSObject;
    activeButton: CSSObject;
    inactiveButton: CSSObject;
    activeButtonStates: CSSObject;
  };
  dropShadowBox: {
    padding: number;
    backgroundColor: string;
    boxShadow: string;
    border: number;
    borderRadius: number;
    borderColor: string;
  };
  dismissibleBox: {
    bgcolor: string;
    py: number;
    px: number;
    borderRadius: number;
  };
  navigationRail: {
    container: CSSObject;
    iconButton: CSSObject;
    activeBackgroundColor: string;
    inactiveBackgroundColor: string;
  };
  navigationDrawer: {
    paper: CSSObject;
    listItem: CSSObject;
    subListItem: CSSObject;
    subListItemInnerPadding: CSSObject;
    text: CSSObject;
    headingStyle: CSSObject;
  };
  verifyCode: {
    inputField: CSSObject;
  };
  teamsSettings: {
    grid: CSSObject;
  };
  switchVariants: {
    small: CSSObject;
    medium: CSSObject;
  };
  pointer: CSSObject;
}

export const provideDropShadow =
  "rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px";

const provideTheme: Theme = createTheme({ palette });
export const custom: CustomTheme = {
  contentWidth: {
    menuClosedWidth: "90px",
    menuOpenWidth: "330px",
  },
  iconButton: {
    position: "absolute",
    left: "10px",
    display: "flex",
    alignItems: "center",
  },
  customIconButton: {
    backgroundColor: "black",
    color: "white",
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
  plainTextButton: {
    cursor: "pointer",
    userSelect: "none",
    lineHeight: 2,
    textTransform: "none",
    alignItems: "center",
    display: "flex",
  },
  saveChangesBox: {
    display: "flex",
    position: "fixed",
    boxSizing: "border-box",
    bottom: 10,
    height: "80px",
    border: `1px solid ${provideTheme.palette.gray.light}`,
    backgroundColor: "white",
    alignItems: "center",
    zIndex: 1,
    padding: 2,
  },
  notificationAvatar: {
    width: 22,
    height: 22,
    fontSize: "0.7rem",
    mt: 1.85,
    pt: 0.25,
    boxSizing: "border-box",
  },
  pillNavigation: {
    container: {
      display: "flex",
      overflowX: "auto",
      pl: 4,
    },
    button: {
      display: "inline-flex",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
      height: "30px",
      borderRadius: "15px",
      ml: "-30px",
      whiteSpace: "nowrap",
      transition: "none",
      zIndex: 0,
    },
    activeButton: {
      padding: "2px 30px 0 30px",
      color: "white",
      zIndex: 1,
    },
    inactiveButton: {
      padding: "2px 50px 0 50px",
      backgroundColor: provideTheme.palette.secondary.light,
      color: "black",
    },
    activeButtonStates: {
      purple: {
        backgroundColor: provideTheme.palette.secondary.main,
      },
      black: {
        backgroundColor: "black",
      },
    },
  },
  loginWave: {
    position: "fixed",
    zIndex: 0,
    display: "none",
    [provideTheme.breakpoints.up("md")]: {
      display: "block",
    },
    bottom: 0,
    right: 0,
  },
  dropShadowBox: {
    padding: 4,
    backgroundColor: "white",
    boxShadow: provideDropShadow,
    border: 1,
    borderRadius: 0.8,
    borderColor: provideTheme.palette.lightPurple,
  },
  dismissibleBox: {
    bgcolor: provideTheme.palette.gray.light,
    py: 1,
    px: 2,
    borderRadius: 27,
  },
  sideNavigation: {
    container: {
      position: "fixed",
      transition: "left 0.3s ease-in-out",
      boxSizing: "border-box",
      zIndex: 2,
    },
    button: {
      position: "fixed",
      top: provideTheme.spacing(2),
      left: provideTheme.spacing(2.25),
      zIndex: 4,
      backgroundColor: "white",
      border: `1px solid ${provideTheme.palette.gray.light}`,
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
  navigationRail: {
    container: {
      display: "flex",
      flexDirection: "column",
      width: 80,
      height: "100dvh",
      borderRight: `2px solid ${provideTheme.palette.secondary.light}`,
      justifyContent: "space-between",
      backgroundColor: "white",
      pt: 8,
      [provideTheme.breakpoints.up("sm")]: {
        pt: 2,
      },
      pb: 2,
      boxSizing: "border-box",
      position: "relative",
      zIndex: 2,
      overflowY: "auto",
    },
    iconButton: {
      color: "black",
      padding: "12px",
      borderRadius: "25px",
      width: "56px",
      height: "32px",
      transition: "background-color 0.5s ease",
      "&:hover": {
        backgroundColor: provideTheme.palette.gray.light,
        borderRadius: "25px",
      },
      "&.Mui-selected": {
        backgroundColor: provideTheme.palette.secondary.dark,
        "&:hover": {
          backgroundColor: provideTheme.palette.secondary.dark,
        },
      },
    },
    activeBackgroundColor: provideTheme.palette.secondary.dark,
    inactiveBackgroundColor: "transparent",
  },
  navigationDrawer: {
    paper: {
      "& .MuiDrawer-paper": {
        position: "absolute",
        zIndex: 2,
        width: "300px",
        pl: "90px",
        pr: "10px",
        boxSizing: "border-box",
        backgroundColor: provideTheme.palette.secondary.light,
        borderRadius: "25px",
        border: "none",
      },
    },
    listItem: {
      borderRadius: "25px",
      "&:hover": {
        backgroundColor: provideTheme.palette.gray.light,
        borderRadius: "25px",
      },
      "&.Mui-selected": {
        backgroundColor: provideTheme.palette.secondary.dark,
        "&:hover": {
          backgroundColor: provideTheme.palette.secondary.dark,
        },
      },
      padding: "5px 15px",
    },
    subListItem: {
      borderRadius: "25px",
      padding: "5px 15px",
      "&:hover": {
        backgroundColor: provideTheme.palette.gray.light,
        borderRadius: "25px",
      },
      "&.Mui-selected": {
        backgroundColor: provideTheme.palette.secondary.dark,
        "&:hover": {
          backgroundColor: provideTheme.palette.secondary.dark,
        },
      },
      "&:first-of-type": {
        mt: "1px",
      },
    },
    subListItemInnerPadding: {
      pl: "30px",
    },
    text: {
      display: "flex",
      alignItems: "center",
      margin: "5px 0",
      pl: "16px",
    },
    headingStyle: {
      padding: "10px 0 8px 16px",
    },
  },
  verifyCode: {
    inputField: {
      width: 43,
      [provideTheme.breakpoints.down("sm")]: {
        width: 35,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
      },
    },
  },
  teamsSettings: {
    grid: {
      padding: "15px",
      border: `1px solid ${provideTheme.palette.gray.light}`,
      borderRadius: "20px",
    },
  },
  switchVariants: {
    small: {
      width: 44,
      height: 24,
      padding: 0,
      display: "flex",
      "&:active": {
        "& .MuiSwitch-thumb": {
          width: 14,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          transform: "translateX(9px)",
        },
      },
      "& .MuiSwitch-switchBase": {
        padding: "5px",
        color: provideTheme.palette.text.secondary,
        "&.Mui-checked": {
          transform: "translateX(20px)",
          color: "white",
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: provideTheme.palette.primary.main,
          },
        },
      },
      "& .MuiSwitch-thumb": {
        width: 14,
        height: 14,
        borderRadius: 7,
        transition: "width 200ms",
      },
      "& .MuiSwitch-track": {
        borderRadius: 20,
        opacity: 1,
        backgroundColor: provideTheme.palette.accent.cyan,
        boxSizing: "border-box",
      },
    },
    medium: {
      width: 51,
      height: 30,
      padding: 0,
      display: "flex",
      "--switch-track-color": provideTheme.palette.primary.main,
      "&:active": {
        "& .MuiSwitch-thumb": {
          width: 20,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          transform: "translateX(9px)",
        },
      },
      "& .MuiSwitch-switchBase": {
        padding: "5px",
        color: provideTheme.palette.text.secondary,
        "&.Mui-checked": {
          transform: "translateX(20px)",
          color: "white",
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "var(--switch-track-color)",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: provideTheme.palette.gray.dark,
        transition: "width 200ms",
      },
      "& .Mui-checked .MuiSwitch-thumb": {
        backgroundColor: "white",
      },
      "& .MuiSwitch-track": {
        borderRadius: 20,
        opacity: 1,
        backgroundColor: provideTheme.palette.accent.cyan,
        boxSizing: "border-box",
      },
    },
  },
  pointer: {
    height: 26,
    borderRadius: 12,
    backgroundColor: "black",
    color: "white",
    pl: 1,
    "& .MuiChip-icon": {
      marginLeft: "-2px",
    },
  },
};
