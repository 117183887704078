import useAxios from "axios-hooks";

const useFetchCompanies = () => {
  const [
    { data: companyList, loading: companyLoading, error: companyError },
    fetchCompanies,
  ] = useAxios(
    {
      url: "/api/v1/companies",
      method: "GET",
    },
    {
      useCache: false,
    },
  );

  const handleFetchCompanies = async () => {
    try {
      await fetchCompanies();
    } catch (error) {
      console.error("Fetch request failed:", error);
      return { error };
    }
  };

  return { handleFetchCompanies, companyList, companyLoading, companyError };
};

export default useFetchCompanies;
