import React, { useState } from "react";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid2";
import useFetchUserList from "../../hooks/api/useFetchUserList";
import { columns } from "./data/model";
import SearchIcon from "@mui/icons-material/Search";
import ProvideButton from "../../components/Buttons/ProvideButton";
import UserIcon from "../../components/Icons/UserIcon/UserIcon";
import CustomIconButton from "../../components/Buttons/CustomIconButton";
import CustomToolbar from "./CustomToolbar";

const UserListScreen: React.FC = () => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const { rows, rowCount, loading, error } = useFetchUserList(
    paginationModel.page,
  );

  return (
    <>
      <Grid container alignItems="center" justifyContent="end" spacing={2}>
        <IconButton>
          <MoreVertIcon sx={{ color: "black" }} />
        </IconButton>
        <Box display="flex" alignItems="bottom" gap={2} flexDirection="row">
          <ProvideButton
            variant="contained"
            sx={{
              width: 150,
              height: 40,
            }}
          >
            <Box display="flex" alignItems="bottom" gap={1} flexDirection="row">
              <PersonAddIcon />
              ADD USER
            </Box>
          </ProvideButton>
          <CustomIconButton>
            <SearchIcon />
          </CustomIconButton>
        </Box>
      </Grid>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            flexDirection="row"
            sx={{ flexWrap: "wrap", justifyContent: "flex-start" }}
          >
            <UserIcon />
            <Typography component="h1" variant="h1" color="black">
              Users
            </Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap={1}
            flexDirection="row"
            sx={{
              flexWrap: "wrap",
              justifyContent: { xs: "flex-start", sm: "flex-start" },
            }}
          >
            <Chip variant="outlined" label="All Users" />
            <Chip variant="outlined" label={rowCount + " total accounts"} />
            <AddIcon />
            <Chip
              label="18 with active enquiries"
              variant="filled"
              icon={<InfoIcon sx={{ fontSize: 20 }} />}
            />
          </Box>
        </Stack>
        {error && (
          <Typography color="error">{error.response?.data.message}</Typography>
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          loading={loading}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[50, 100]}
          slots={{ toolbar: CustomToolbar }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Stack>
    </>
  );
};

export default UserListScreen;
