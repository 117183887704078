import React from "react";

// MUI Components
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Custom Components
import ProvideButton from "../Buttons/ProvideButton";
import PlainTextButton from "../Buttons/PlainTextButton";

// Redux Hooks and Store
import { useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";

interface AddUserSendLoginProps {
  onStep: (step: string) => void;
  onFormSubmit: () => void;
}

const AddUserSendLogin: React.FC<AddUserSendLoginProps> = ({
  onStep,
  onFormSubmit,
}) => {
  const userDataRedux = useAppSelector(
    (state: RootState) => state.addUser.addUserData,
  );

  // submit the data
  const submit = () => {
    onFormSubmit();
  };

  // back to previous step
  const goBack = () => {
    onStep("teams");
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      <Typography variant="h4" component="h4">
        4. Send Login
      </Typography>
      <Typography variant="body3">
        Click Submit to send an invitation to{" "}
        <strong>{userDataRedux?.email}</strong>
      </Typography>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProvideButton onClick={submit} variant="contained">
            SUBMIT
            <ArrowForwardRoundedIcon sx={{ ml: 1 }} />
          </ProvideButton>
        </Grid>
      </Grid>
      <PlainTextButton onClick={goBack}>
        <ChevronLeftIcon />
        Back
      </PlainTextButton>
    </Stack>
  );
};

export default AddUserSendLogin;
