/*
  This is the data structure used for the rail and drawer components.
  Top level is for the navigation rail - id and title are required. 
  Without a path, it will be purely cosmetic and not a button. If an
  object has an items array, this will render the drawer content,
  which is grouped by arrays to have spacing. This will also signal
  the component to slide out the drawer. If a drawer item has a subItem
  property, the component will render a dropdown menu. If a property only has
  title and heading, the component will render a heading without functionality.
  Make sure everything clickable has an id - this is required to avoid
  flattening the arrays and maintain grouping.

*/
export interface DrawerSubItem {
  id?: number;
  title: string;
  path?: string;
  permissions?: string[];
  heading?: boolean;
  subItems?: DrawerSubItem[];
}

export interface DrawerMenuItem {
  id: number;
  title: string;
  path?: string;
  permissions?: string[];
  items?: DrawerSubItem[][] | DrawerSubItem[];
}

export const navigationDrawerData: DrawerMenuItem[] = [
  {
    id: 1,
    title: "Dashboard",
    path: "./dashboard",
  },
  {
    id: 2,
    title: "Enquiries",
    path: "./enquiries",
    items: [
      [
        { title: "Funding Enquiries", heading: true },
        { id: 3, title: "All Enquiries", path: "./all-enquiries" },
        { id: 4, title: "Business Enquiries", path: "./business-enquiries" },
        { id: 5, title: "Property Enquiries", path: "./property-enquiries" },
        { id: 6, title: "In Application", path: "./in-application" },
      ],
      [
        { title: "Views", heading: true },
        {
          id: 7,
          title: "My Views",
          subItems: [
            { id: 8, title: "All Enquiries", path: "./all-enquiries" },
            {
              id: 9,
              title: "Business Enquiries",
              path: "./business-enquiries",
            },
            {
              id: 10,
              title: "Property Enquiries",
              path: "./property-enquiries",
            },
          ],
        },
        {
          id: 11,
          title: "More Views",
          subItems: [
            { id: 12, title: "All Enquiries", path: "./all-enquiries" },
            {
              id: 13,
              title: "Business Enquiries",
              path: "./business-enquiries",
            },
            {
              id: 14,
              title: "Property Enquiries",
              path: "./property-enquiries",
            },
          ],
        },
      ],
      [
        { title: "Add New", heading: true },
        { id: 15, title: "Add New Enquiry", path: "./enquiry-prototype" },
        { id: 16, title: "Add New View", path: "./add-new-view" },
      ],
    ],
  },
  {
    id: 17,
    title: "Products",
    path: "./create-lending-option",
  },
  {
    id: 20,
    title: "Users",
    path: "./users",
    items: [
      { id: 21, title: "Manage Users", path: "./users" },
      { id: 22, title: "Add New User", path: "./add-user" },
    ],
  },
  {
    id: 23,
    title: "Accounts",
    path: "./accounts",
    items: [
      { id: 24, title: "View Accounts", path: "./accounts" },
      { id: 25, title: "Add Account", path: "./add-account" },
    ],
  },
  {
    id: 26,
    title: "Files",
    path: "./files",
  },
  {
    id: 27,
    title: "Renewals",
    path: "./renewals",
  },
  {
    id: 28,
    title: "Lenders",
    path: "./lenders",
    items: [
      {
        id: 29,
        title: "View Lenders",
        path: "./lending-options",
        permissions: ["view-all-products"],
      },
      { id: 30, title: "Add Lender", path: "./add-lender" },
    ],
  },
  {
    id: 31,
    title: "Reporting",
    path: "./reporting",
  },
  {
    id: 32,
    title: "System",
    path: "./system",
  },
];
