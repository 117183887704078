import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  Box,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLoadScript } from "@react-google-maps/api";
import { Library } from "@googlemaps/js-api-loader";
import { Address } from "../../store/slices/interface/interface";

const googleLibraries: Library[] = ["places"];

interface AddressComponentProps {
  onChange?: (address: Address) => void;
  address?: Address | null;
}

const defaultAddressData: Address = {
  id: "",
  address_1: "",
  address_2: "",
  address_3: "",
  city: "",
  region: "",
  country: "gb",
  post_code: "",
};

const AddressComponent: React.FC<AddressComponentProps> = ({
  onChange,
  address = defaultAddressData,
}) => {
  const addressData = address || defaultAddressData;
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: googleLibraries,
  });

  useEffect(() => {
    if (isLoaded) {
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    }
  }, [isLoaded]);

  useEffect(() => {
    if (!inputValue || !sessionToken) {
      setSuggestions([]);
      return;
    }

    const autocompleteService = new google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      { input: inputValue, sessionToken },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions || []);
        }
      },
    );
  }, [inputValue, sessionToken]);

  const fetchPlaceDetails = (placeId: string) => {
    const placesService = new google.maps.places.PlacesService(
      document.createElement("div"),
    );

    placesService.getDetails(
      { placeId, fields: ["address_components"], sessionToken },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const addressComponents = place.address_components?.reduce(
            (acc, component) => {
              const types = component.types;
              if (types.includes("street_number")) {
                acc = { ...acc, address_1: component.long_name };
              }
              if (types.includes("route")) {
                acc = { ...acc, address_2: component.long_name };
              }
              if (types.includes("locality") || types.includes("postal_town")) {
                acc = { ...acc, city: component.long_name };
              }
              if (types.includes("administrative_area_level_1")) {
                acc = { ...acc, region: component.long_name };
              }
              if (types.includes("postal_code")) {
                acc = { ...acc, post_code: component.long_name };
              }
              return acc;
            },
            addressData,
          );

          if (!onChange) return;
          onChange(addressComponents ?? addressData);
          setIsAddressSelected(true);
        }
      },
    );
  };

  const handleSelect = (place: google.maps.places.AutocompletePrediction) => {
    const placeId = place.place_id;
    fetchPlaceDetails(placeId);
  };

  const handleDelete = () => {
    if (!onChange) return;
    onChange(addressData);
    setIsAddressSelected(false);
  };

  const handleInputChange = (field: string, value: string) => {
    if (!onChange) return;
    onChange({
      ...addressData,
      [field]: value,
    });
    setIsAddressSelected(true);
  };

  return (
    <Box sx={{ mx: "auto" }}>
      <Stack gap={7}>
        {!loadError && (
          <Stack gap={1.5}>
            <Autocomplete
              freeSolo
              options={suggestions}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              onChange={(_, value) => {
                if (value && typeof value !== "string") {
                  handleSelect(value);
                }
              }}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
                if (newInputValue === "") {
                  handleDelete();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter an address"
                  fullWidth
                />
              )}
            />
            {isAddressSelected && (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ mb: 2, cursor: "pointer" }}
                onClick={handleDelete}
              >
                <IconButton sx={{ color: "red" }}>
                  <DeleteIcon />
                </IconButton>
                <Typography variant="body2" sx={{ color: "red" }}>
                  DELETE
                </Typography>
              </Stack>
            )}
          </Stack>
        )}

        <Stack gap={1.5}>
          <TextField
            label="Name/No"
            value={addressData.address_1}
            onChange={(e) => handleInputChange("address_1", e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Street"
            value={addressData.address_2}
            onChange={(e) => handleInputChange("address_2", e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Town/City"
            value={addressData.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Region"
            value={addressData.region}
            onChange={(e) => handleInputChange("region", e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Postcode"
            value={addressData.post_code}
            onChange={(e) => handleInputChange("post_code", e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddressComponent;
