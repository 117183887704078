/*
  This is the parent component for the side navigation in
  the app. Add this parent component, a data structure,
  and an icon map (located in the data folder) to implement
  this menu. NavigationRail is the nav to the left, with
  icon buttons, logo and notifications. NavigationDrawer
  is the slide out drawer and all contents.  

*/

import React, { ReactNode, useState } from "react";
import NavigationRail from "./NavigationRail";
import NavigationDrawer from "./NavigationDrawer";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  navigationDrawerData,
  DrawerMenuItem,
  DrawerSubItem,
} from "./data/navigationDrawerData";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  setDrawerOpen,
  selectDrawerOpen,
} from "../../store/slices/navigationSlice";
import { useNavigate } from "react-router";

interface SideNavigationProps {
  children?: ReactNode;
}

const SideNavigation: React.FC<SideNavigationProps> = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector(selectDrawerOpen);
  const navigate = useNavigate();

  const [drawerContent, setDrawerContent] = useState(navigationDrawerData[0]);
  const [clickedIndex, setClickedIndex] = useState<number>(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState<number | null>(0);
  const [menuVisible, setMenuVisible] = useState(false);

  const { container, button } = theme.custom.sideNavigation;

  /*
    Function to find the ID of the first sub-item of a given menu item,
    this is required as when you click on a rail icon, it needs to highlight
    the first option.

  */
  const findFirstSubItemId = (
    data: DrawerMenuItem[],
    id: number | null,
  ): number | null =>
    data
      .find((item) => item.id === id)
      ?.items?.flat()
      .find((subItem): subItem is DrawerSubItem => !!subItem.id)?.id || null;

  /*
    Handles opening the nav and setting the content

  */
  const handleNavOpen = (index: number | null) => {
    if (index && navigationDrawerData[index].items) {
      dispatch(setDrawerOpen(true));
      setDrawerContent(navigationDrawerData[index] || navigationDrawerData[0]);
    }
  };

  /*
    This is being called by both the rail and also the drawer. When
    we select an item from the drawer, we want to highlight the
    corresponding rail item. When we click a rail item, we want to
    select the first drawer item in the submenu.

  */
  const handleNavClick = (path: string, id: number | null) => {
    const index = navigationDrawerData.findIndex((item) => item.id === id);
    setClickedIndex(index);

    const selectedSubMenuId = findFirstSubItemId(navigationDrawerData, id);
    setSelectedSubIndex(selectedSubMenuId);

    // TESTING PURPOSES - FOR THE LOVE OF GOD REMOVE
    if (path !== "./dashboard") navigate(path);
  };

  /*
    This handles the mouse out functionality. If we have clicked an item,
    we don't want the menu to close when you mouse out. If we haven't,
    we want the menu to close.

  */
  const handleDrawerLeave = () => {
    if (
      navigationDrawerData[clickedIndex] &&
      navigationDrawerData[clickedIndex].items
    ) {
      handleNavOpen(clickedIndex);
    } else {
      handleDrawerClose();
    }
  };

  /*
    Dispatches to Redux store to set drawer open state.

  */
  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
  };

  /*
    Shows and hides menu for mobile devices and smaller screens.

  */
  const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <Box
        sx={{
          ...container,
          left: isSmallScreen && !menuVisible ? "-100%" : 0,
        }}
      >
        <NavigationDrawer
          open={drawerOpen}
          onLeave={handleDrawerLeave}
          onClose={handleDrawerClose}
          drawerContent={drawerContent}
          onNavClick={handleNavClick}
          controlledActiveIndex={selectedSubIndex}
        />
        <NavigationRail
          onNavOpen={handleNavOpen}
          onNavClick={handleNavClick}
          onClose={handleDrawerClose}
          controlledActiveIndex={clickedIndex}
        />
      </Box>
      {isSmallScreen && (
        <IconButton onClick={toggleMenuVisibility} sx={button}>
          <MenuIcon />
        </IconButton>
      )}
      {children}
    </>
  );
};

export default SideNavigation;
