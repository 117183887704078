import React from "react";
import { Stack, Box, Typography, Slider, TextField } from "@mui/material";

interface RangeSliderProps {
  title?: string;
  sliderValue: [number, number];
  min?: number;
  max?: number;
  onSliderChange: (
    _event: React.SyntheticEvent | Event,
    newValue: number | number[],
  ) => void;
  onSliderCommit: (
    _event: React.SyntheticEvent | Event,
    newValue: number | number[],
  ) => void;
  onInputChange: (
    key: "minimumValue" | "maximumValue",
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  title,
  sliderValue,
  min,
  max,
  onSliderChange,
  onSliderCommit,
  onInputChange,
}) => {
  return (
    <Stack gap={3} sx={{ width: "100%", boxSizing: "border-box", mb: 1 }}>
      <Typography component="p" variant="body7">
        {title}
      </Typography>
      <Slider
        value={sliderValue}
        onChange={onSliderChange}
        onChangeCommitted={onSliderCommit}
        valueLabelDisplay="auto"
        min={min}
        max={max}
      />
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <TextField
          label="Minimum Value"
          type="number"
          value={sliderValue[0] ?? ""}
          onChange={onInputChange("minimumValue")}
          fullWidth
        />
        <TextField
          label="Maximum Value"
          type="number"
          value={sliderValue[1] ?? ""}
          onChange={onInputChange("maximumValue")}
          fullWidth
        />
      </Box>
    </Stack>
  );
};

export default RangeSlider;
