import { Templates } from "@rjsf/mui";
import { getDefaultRegistry } from "@rjsf/core";
import { TemplatesType } from "@rjsf/utils";

const defaultTemplates = getDefaultRegistry().templates;
type TemplateKeys = keyof typeof Templates | keyof typeof defaultTemplates;

export const useSchemaFormTemplate = <T extends TemplateKeys>(
  templateName: T,
): TemplatesType[T] => {
  const templates = { ...defaultTemplates, ...Templates };
  return templates[templateName] as TemplatesType[T];
};
