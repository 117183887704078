import React, { ReactNode } from "react";
import { Avatar, Box, Typography } from "@mui/material";

interface SingleViewHeaderProps {
  title: string;
  avatarSrc?: string;
  avatarInitials: string;
  children?: ReactNode;
}

const SingleViewHeader: React.FC<SingleViewHeaderProps> = ({
  title,
  avatarSrc = "",
  avatarInitials,
  children,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      gap={1.5}
      sx={{
        flexWrap: "wrap",
        justifyContent: { xs: "flex-start", sm: "flex-start" },
      }}
    >
      <Avatar
        title="Avatar"
        sx={{
          width: 45,
          height: 45,
          fontSize: "1.25rem",
          cursor: "pointer",
          backgroundColor: "lightBlue",
        }}
        src={avatarSrc}
      >
        {avatarInitials}
      </Avatar>
      <Typography component="h2" variant="display" sx={{ mb: 0.75 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default SingleViewHeader;
