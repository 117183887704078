import useAxios from "axios-hooks";

const useFetchRoles = () => {
  const [
    { data: rolesData, loading: rolesLoading, error: rolesError },
    fetchRoles,
  ] = useAxios(
    {
      url: "/api/v1/roles",
      method: "GET",
    },
    {
      useCache: false,
      manual: true,
    },
  );

  const handleFetchAllRoles = async () => {
    try {
      await fetchRoles();
    } catch (error) {
      console.error("Fetch request failed:", error);
      return { error };
    }
  };

  return { handleFetchAllRoles, rolesData, rolesLoading, rolesError };
};

export default useFetchRoles;
