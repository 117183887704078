import React, { useEffect } from "react";

// MUI Components
import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

// Custom Components
import ProvideButton from "../Buttons/ProvideButton";
import PlainTextButton from "../Buttons/PlainTextButton";
import UserTeams from "../UserTeams/UserTeams";

// Redux Hooks and Store
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import { setAddUserData } from "../../store/slices/addUserSlice";

// API Hook
import useFetchCompanyTeams from "../../hooks/api/useFetchCompanyTeams";

interface TeamsProps {
  onStep: (step: string) => void;
}

const AddUserTeams: React.FC<TeamsProps> = ({ onStep }) => {
  const dispatch = useAppDispatch();

  // Redux State Selectors
  const userDataRedux = useAppSelector(
    (state: RootState) => state.addUser.addUserData,
  );
  const companyId = useAppSelector(
    (state: RootState) => state.addUser.selectedCompanyId,
  );
  const teamsPermissions = useAppSelector((state: RootState) =>
    state.permissionsList.permissions.includes("update-all-teams"),
  );

  // makes sense to have this in here, as we need to load
  // teams data based on selected company.
  const {
    handleFetchCompanyTeams,
    fetchingTeams,
    fetchTeamsError,
    fetchTeamsData,
  } = useFetchCompanyTeams();

  useEffect(() => {
    if (companyId) handleFetchCompanyTeams(companyId);
  }, [companyId]);

  // Handles team selection changes
  const handleSelectionChange = (updatedIds: string[]) => {
    dispatch(
      setAddUserData({
        teams: updatedIds,
      }),
    );
  };

  // Navigates to the next step
  const saveAndContinue = () => {
    onStep("sendLogin");
  };

  // Navigates back to the previous step
  const goBack = () => {
    onStep("companyRoles");
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      <Typography variant="h4" component="h4">
        3. Teams
      </Typography>
      <Stack spacing={4} sx={{ minHeight: 140 }}>
        {fetchingTeams ? (
          <CircularProgress color="primary" />
        ) : fetchTeamsData ? (
          <UserTeams
            teamsData={fetchTeamsData.data || []}
            teamsTitle={fetchTeamsData.title}
            teamsLogo={fetchTeamsData.logo}
            editable={teamsPermissions}
            selectedIds={userDataRedux?.teams ?? []}
            onSelectionChange={handleSelectionChange}
          />
        ) : fetchTeamsError ? (
          <Typography color="error">
            {fetchTeamsError.response?.data.message}
          </Typography>
        ) : null}
      </Stack>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProvideButton onClick={saveAndContinue} variant="contained">
            CONTINUE
            <ArrowForwardRoundedIcon sx={{ ml: 1 }} />
          </ProvideButton>
        </Grid>
      </Grid>
      <PlainTextButton onClick={goBack}>
        <ChevronLeftIcon />
        Back
      </PlainTextButton>
    </Stack>
  );
};

export default AddUserTeams;
