// React and related hooks
import React, { JSX, useEffect, useState } from "react";

// MUI components for UI elements
import { CircularProgress, Box, Typography, Stack } from "@mui/material";

// External libraries and tools
import validator from "@rjsf/validator-ajv8";
import { IChangeEvent, withTheme } from "@rjsf/core";
import { Theme } from "@rjsf/mui";

// Components used in the screen
import RootAuthScreen from "../RootScreen/RootAuthScreen";
import UserTopNavigation from "../../components/UserTopNavigation/UserTopNavigation";
import LendingIcon from "../../components/Icons/LendingIcon/LendingIcon";
import PropertyIcon from "../../components/Icons/PropertyIcon/PropertyIcon";
import BusinessIcon from "../../components/Icons/BusinessIcon/BusinessIcon";
import CustomRadioButton from "../../components/CustomRadioButton/CustomRadioButton";
import CustomStepperDesktop from "../../components/CustomStepper/CustomStepperDesktop";

// Custom form field templates and widgets
import CustomObjectField from "./custom/CustomObjectField";
import TitleFieldTemplate from "./custom/TitleFieldTemplate";
import CardSectionTemplate from "./custom/CardSectionTemplate";
import ObjectFieldTemplate from "./custom/ObjectFieldTemplate";
import FieldHelpTemplate from "./custom/FieldHelpTemplate";
import CustomCheckboxesWidget from "./custom/CustomCheckboxesWidget";

// Hooks for API calls and scroll functionality
import useFetchCategories from "../../hooks/api/useFetchCategories";
import useFetchSchema from "../../hooks/api/useFetchSchema";
import useScrollListener from "../../hooks/useScrollListener";

// Utility functions
import { sanitizeTitle } from "../../utils/helper";
import LendingOptionSummary from "../../components/LendingOptionSummary/LendingOptionSummary";
import RangeSlider from "../../components/RangeSlider/RangeSlider";

//Interfaces
interface Category {
  id: string;
  name: string;
}

interface Section {
  title: string;
  fields: string[];
}
interface SectionTitles {
  id: string;
  title: string;
}

const Icons: Record<string, JSX.Element> = {
  Property: <PropertyIcon />,
  Business: <BusinessIcon />,
};

const CreateLendingOptionScreen: React.FC = () => {
  // React hooks and state management
  const MaterialUIForm = withTheme(Theme);

  // State variables for managing form data, selected options, and titles
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [titles, setTitles] = useState<SectionTitles[]>([]);
  const [selectedTitleIndex, setSelectedTitleIndex] = useState<number>(0);
  const [formData, setFormData] = useState({});

  // Custom hooks for handling scroll listeners and changing navigation
  const { setSectionRef } = useScrollListener(titles, setSelectedTitleIndex);

  // API Hooks
  const {
    handleFetchCategories,
    categoriesData,
    categoriesLoading,
    categoriesError,
  } = useFetchCategories();

  const { handleFetchSchemas, schemasLoading, schemasError, schemasData } =
    useFetchSchema();

  // Side effects and data fetching
  useEffect(() => {
    handleFetchCategories();
  }, []);

  useEffect(() => {
    if (schemasData.uiSchema?.["ui:sections"]) {
      const sections: Section[] = schemasData.uiSchema["ui:sections"];

      setTitles(
        sections.map((section) => ({
          id: sanitizeTitle(section.title),
          title: section.title,
        })),
      );
    }
  }, [schemasData]);

  // Handlers for option selection and form changes
  const handleOptionSelect = (name: string) => {
    const category = categoriesData.find(
      (category: Category) => category.name === name,
    );

    if (!category || selectedOption === name) return;

    handleFetchSchemas(
      category.product_validation_schema?.name,
      category.product_ui_schema?.name,
    );
    setSelectedOption(name);
  };

  const handleFormChange = (event: IChangeEvent<unknown>) => {
    setFormData(event.formData || {});
  };

  // Temp summary stuff for George - adjust as necessary
  const [fields, setFields] = useState({
    lendingOptionName: "",
    summary: "",
    branchTeam: "",
    internalProduct: "",
  });

  const handleFieldChange = (field: keyof typeof fields, value: string) => {
    // This tanks the form as it's rerendering on keypress. Just a temp solution
    // for layouts. We need to avoid rerendering with the final implementation.

    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSkip = () => {
    console.log("Skipping...");
  };

  // Rendering
  if (categoriesLoading) {
    return (
      <RootAuthScreen>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress color="primary" />
        </Box>
      </RootAuthScreen>
    );
  }

  if (categoriesError) {
    return (
      <RootAuthScreen>
        <Typography color="error">
          {categoriesError.response?.data.message}
        </Typography>
      </RootAuthScreen>
    );
  }

  if (categoriesData && categoriesData.length > 0 && schemasData) {
    return (
      <RootAuthScreen>
        <Stack gap={4}>
          <UserTopNavigation />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ position: "relative" }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              flexDirection="row"
              sx={{ flexWrap: "wrap", justifyContent: "flex-start" }}
            >
              <LendingIcon />
              <Typography component="h1" variant="h1" color="black">
                Create Lending Option
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "block" },
                position: "fixed",
                right: 70,
                top: 80,
                minWidth: 280,
                zIndex: 5,
              }}
            >
              {schemasData?.schema && (
                <CustomStepperDesktop
                  title="Progress"
                  subTitle="Lending Option Data:"
                  steps={titles.map((t) => t.title)}
                  currentStep={selectedTitleIndex}
                  requiresValidation={true}
                />
              )}
            </Box>
          </Box>
          <Box>
            <LendingOptionSummary
              title="Lending Option Summary"
              fields={fields}
              onChange={handleFieldChange}
              onSkip={handleSkip}
            />
          </Box>
          <Box sx={{ maxWidth: 510 }}>
            <RangeSlider
              title={"Funding Amount & Type"}
              sliderValue={[0, 10000]}
              min={0}
              max={10000}
              onSliderChange={() => console.log("slider change")}
              onSliderCommit={() => console.log("slider commit")}
              onInputChange={() =>
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  console.log(event + "on input change");
                }}
            />
          </Box>
          <Stack spacing={4}>
            <Typography component="p" variant="body7">
              What kind of Funding?
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: { sm: 2 },
              }}
            >
              {categoriesData.map((category: Category) => (
                <CustomRadioButton
                  key={category.id}
                  title={category.name}
                  disabled={schemasLoading}
                  svgComponent={Icons[category.name] || <LendingIcon />}
                  selected={selectedOption === category.name}
                  onChange={() => handleOptionSelect(category.name)}
                  containerSx={{
                    mb: { xs: 2, sm: 0 },
                  }}
                />
              ))}
            </Box>

            {schemasLoading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress color="primary" />
              </Box>
            )}

            {schemasError && (
              <Typography color="error">
                {schemasError.response?.data.message || "Failed to load schema"}
              </Typography>
            )}

            {schemasData?.schema && (
              <Box
                sx={{
                  maxWidth: 450,
                }}
              >
                <MaterialUIForm
                  schema={schemasData.schema}
                  uiSchema={schemasData.uiSchema}
                  validator={validator}
                  formData={formData}
                  onChange={handleFormChange}
                  formContext={{
                    sectionTemplate: CardSectionTemplate,
                    setSectionRef,
                  }}
                  fields={{ ObjectField: CustomObjectField }}
                  templates={{
                    TitleFieldTemplate,
                    ObjectFieldTemplate,
                    FieldHelpTemplate,
                  }}
                  widgets={{ customCheckBoxes: CustomCheckboxesWidget }}
                  className="full-width"
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </RootAuthScreen>
    );
  }
};

export default CreateLendingOptionScreen;
