import useAxios from "axios-hooks";
import { EditCompanyPayload } from "../../store/slices/interface/interface";

const useCompany = () => {
  const [
    { data: companyData, loading: fetchingCompany, error: fetchCompanyError },
    fetchCompany,
  ] = useAxios(
    {
      method: "GET",
    },
    { manual: true },
  );

  const [{ loading: editing, error: editError, data: editData }, editCompany] =
    useAxios({ method: "PUT" }, { manual: true });

  const handleFetchCompany = async (id: string) => {
    if (!id) return;

    try {
      await fetchCompany({
        url: `/api/v1/companies/${id}`,
      });
    } catch (error) {
      console.error("Fetch request failed:", error);
      return { error };
    }
  };

  const handleEditCompany = async (id: string, payload: EditCompanyPayload) => {
    if (!id) return;

    if (payload.addresses && !Array.isArray(payload.addresses)) {
      payload.addresses = [payload.addresses];
    }

    try {
      await editCompany({
        url: `/api/v1/companies/${id}`,
        data: payload,
      });
    } catch (error) {
      console.error("Edit company request failed:", error);
      return { error };
    }
  };

  return {
    handleFetchCompany,
    companyData,
    fetchingCompany: fetchingCompany,
    fetchCompanyError: fetchCompanyError,
    editing,
    editError,
    editData,
    handleEditCompany,
  };
};

export default useCompany;
