import React from "react";

// MUI Components
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

// Custom Components
import PlainTextButton from "../Buttons/PlainTextButton";
import ProvideButton from "../Buttons/ProvideButton";
import UserRolesList from "../UserRolesList/UserRolesList";

// Redux Hooks and Store
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import {
  setAddUserData,
  setSelectedCompanyId,
} from "../../store/slices/addUserSlice";

// Interfaces and Props
interface AddUserCompanyRolesProps {
  onStep: (step: string) => void;
}

interface CompanyOption {
  label: string;
  id: string;
}

const AddUserCompanyRoles: React.FC<AddUserCompanyRolesProps> = ({
  onStep,
}) => {
  const dispatch = useAppDispatch();

  // Redux State Selectors
  const userDataRedux = useAppSelector(
    (state: RootState) => state.addUser.addUserData,
  );
  const rolesDataRedux = useAppSelector(
    (state: RootState) => state.addUser.userRolesData,
  );
  const companiesDataRedux = useAppSelector(
    (state: RootState) => state.addUser.companiesData,
  );
  const selectedCompanyId = useAppSelector(
    (state: RootState) => state.addUser.selectedCompanyId,
  );

  // Derived State
  const companyOptions =
    companiesDataRedux?.map((company) => ({
      id: company.id,
      label: company.name,
    })) ?? [];

  // Handles company selection change
  const handleSelectedCompanyChange = (
    event: React.SyntheticEvent,
    value: CompanyOption | null,
  ) => {
    if (!value || value.id === selectedCompanyId) return;
    dispatch(setSelectedCompanyId(value.id));
  };

  // Handles role selection changes
  const handleRolesChange = (data: string[]) => {
    dispatch(
      setAddUserData({
        roles: data,
      }),
    );
  };

  // Navigates to the next step
  const saveAndContinue = () => {
    onStep("teams");
  };

  // Navigates back to the previous step
  const goBack = () => {
    onStep("contactDetails");
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      <Typography variant="h4" component="h4">
        2. Company & Roles
      </Typography>
      <Autocomplete
        renderInput={(params) => <TextField {...params} label="Company" />}
        value={
          companyOptions.find((option) => option.id === selectedCompanyId) ??
          companyOptions[0] ??
          null
        }
        onChange={handleSelectedCompanyChange}
        options={companyOptions}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionKey={(option) => option.id}
        disabled={(companiesDataRedux?.length ?? 0) < 2}
        disableClearable
      />
      <Stack spacing={2} sx={{ maxWidth: 293 }}>
        <Typography variant="body5">User Roles</Typography>
        <UserRolesList
          rolesList={rolesDataRedux ?? []}
          selectedRoles={userDataRedux?.roles || []}
          onRolesChange={handleRolesChange}
        />
      </Stack>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ProvideButton onClick={saveAndContinue} variant="contained">
            CONTINUE
            <ArrowForwardRoundedIcon sx={{ ml: 1 }} />
          </ProvideButton>
        </Grid>
      </Grid>
      <PlainTextButton onClick={goBack}>
        <ChevronLeftIcon />
        Back
      </PlainTextButton>
    </Stack>
  );
};

export default AddUserCompanyRoles;
