import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { EditCompanyPayload } from "../../store/slices/interface/interface";
import useValidateInput, { ValidationRule } from "../../hooks/useValidateInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

interface CompanyDetailsFieldsProps {
  formData: EditCompanyPayload | null;
  onFormDataChange: (data: EditCompanyPayload) => void;
  onValidated?: (validate: boolean) => void;
}

type CompanyDetailsFields =
  | "name"
  | "number"
  | "type"
  | "company_type"
  | "phone_number"
  | "incorporation_date"
  | "fca_number";

const CompanyDetailsFields: React.FC<CompanyDetailsFieldsProps> = ({
  formData,
  onFormDataChange,
  onValidated,
}) => {
  const { errors, validateField } = useValidateInput();

  useEffect(() => {
    if (onValidated) {
      const hasErrors = Object.values(errors).some((error) => error?.error);
      onValidated(!hasErrors);
    }
  }, [errors, onValidated]);

  const handleFormChange =
    (field: CompanyDetailsFields, rule: ValidationRule, displayName: string) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;

      validateField(rule, value, field, false, null, displayName);

      onFormDataChange({ ...formData, [field]: value });
    };

  const handleDateChange = (newDate: Dayjs | null) => {
    onFormDataChange({
      ...formData,
      incorporation_date: newDate ? dayjs(newDate).toISOString() : "",
    });
  };

  return (
    <>
      <TextField
        id="name"
        label="Company Name"
        value={formData?.name || ""}
        variant="outlined"
        onChange={handleFormChange("name", "alphanumeric", "Company Name")}
        placeholder="Company Name"
        fullWidth
        error={errors.name?.error}
        helperText={errors.name?.helperText}
      />
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            id="number"
            label="Company Number"
            placeholder="OC123456"
            variant="outlined"
            value={formData?.number || ""}
            onChange={handleFormChange(
              "number",
              "alphanumeric",
              "Company Number",
            )}
            fullWidth
            error={errors.number?.error}
            helperText={errors.number?.helperText}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            id="company_type"
            label="Company Type"
            placeholder="LLP"
            variant="outlined"
            value={formData?.company_type || ""}
            onChange={handleFormChange("company_type", "text", "Company Type")}
            fullWidth
            error={errors.company_type?.error}
            helperText={errors.company_type?.helperText}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Incorporated on"
              value={
                formData?.incorporation_date
                  ? dayjs(formData.incorporation_date)
                  : null
              }
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  sx: { width: "100%" },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            id="phone_number"
            label="Phone"
            variant="outlined"
            value={formData?.phone_number || ""}
            placeholder={"+447739477105"}
            onChange={handleFormChange("phone_number", "text", "Phone Number")}
            fullWidth
            error={errors.phone_number?.error}
            helperText={errors.phone_number?.helperText}
          />
        </Grid>
      </Grid>
      <TextField
        id="fca_number"
        label="FCA Number (if applicable)"
        value={formData?.fca_number || ""}
        placeholder={"FCA123"}
        variant="outlined"
        onChange={handleFormChange("fca_number", "alphanumeric", "FCA Number")}
        fullWidth
        error={errors.fca_number?.error}
        helperText={errors.fca_number?.helperText}
      />
    </>
  );
};

export default CompanyDetailsFields;
