import React from "react";
import DropShadowBox from "../DropShadowBox/DropShadowBox";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { AnimatedBounceContainer } from "../../styled/AnimatedBounceContainer.styled";
import RedCrossIcon from "../Icons/RedCrossIcon/RedCrossIcon";
import CheckmarkCircle from "../Icons/CheckmarkCircle/CheckmarkCircle";
import ProvideButton from "../Buttons/ProvideButton";
import ModalContainer from "../Modal/ModalContainer";

interface SaveConfirmationDialogProps {
  isModalOpen: boolean;
  editing: boolean;
  editError: string;
  onConfirm: () => void;
}

const SaveConfirmationDialog: React.FC<SaveConfirmationDialogProps> = ({
  onConfirm,
  isModalOpen = false,
  editing = false,
  editError = null,
}) => {
  return (
    <ModalContainer isOpen={isModalOpen}>
      <DropShadowBox minWidth={200}>
        <Stack alignItems="center" spacing={2}>
          <AnimatedBounceContainer>
            {editing ? (
              <CircularProgress color="primary" />
            ) : editError ? (
              <RedCrossIcon />
            ) : (
              <CheckmarkCircle />
            )}
          </AnimatedBounceContainer>

          {editing ? (
            <Typography textAlign="center">Saving Changes...</Typography>
          ) : editError ? (
            <Typography color="error" textAlign="center">
              {editError || "An error occurred."}
            </Typography>
          ) : (
            <Box>
              <Typography textAlign="center">
                Your changes have been saved.
              </Typography>
            </Box>
          )}

          <ProvideButton
            variant="contained"
            onClick={() => onConfirm()}
            sx={{ width: "100%" }}
          >
            Ok
          </ProvideButton>
        </Stack>
      </DropShadowBox>
    </ModalContainer>
  );
};

export default SaveConfirmationDialog;
