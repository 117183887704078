import React, { JSX, useEffect, useState } from "react";

// Third-Party Library Imports
import { useParams } from "react-router";
import { Box, Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";

// Hooks
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import useUser from "../../hooks/api/useUser";
import useFetchRoles from "../../hooks/api/useFetchRoles";
import useCompany from "../../hooks/api/useCompany";
import useFetchCompanyTeams from "../../hooks/api/useFetchCompanyTeams";

// Redux Store & State Management
import { RootState } from "../../store/store";
import {
  setUserViewCompanyData,
  setUserViewRolesData,
  setUserViewData,
  checkUrlForUserOption,
  setUserViewOption,
  setUserViewTeamsData,
  resetUserViewChanges,
  applyUserViewChanges,
} from "../../store/slices/userViewSlice";

// Component Imports
import PillNavigation from "../../components/PillNavigation/PillNavigation";
import UserSingleViewMain from "../../components/UserSingleView/UserSingleViewMain";
import UserSingleViewActivities from "../../components/UserSingleView/UserSingleViewActivities";
import UserSingleViewTeams from "../../components/UserSingleView/UserSingleViewTeams";
import UserSingleViewEnquiries from "../../components/UserSingleView/UserSingleViewEnquiries";
import UserSingleViewDocuments from "../../components/UserSingleView/UserSingleViewDocuments";
import UserSingleViewNotifications from "../../components/UserSingleView/UserSingleViewNotifications";

// Data & Types
import { userPillData } from "./data/userData";
import SaveCancelButtons from "../../components/SaveCancelBox/SaveCancelButtons";
import SaveConfirmationDialog from "../../components/SaveConfirmationDialog/SaveConfirmationDialog";
import useAxiosErrorMessages from "../../hooks/useAxiosErrorMessages";
import SingleViewLayout from "../../components/SingleViewLayout/SingleViewLayout";
import SingleViewHeader from "../../components/SingleViewLayout/SingleViewHeader";
import { getInitialsFromName } from "../../utils/helper";

// Component Definition
const UserSingleViewScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // Dispatch and Redux Selectors
  const dispatch = useAppDispatch();
  const {
    userViewSliceOption: userOption,
    hasChanges,
    isValidated,
    originalUserViewData,
    userViewData,
  } = useAppSelector((state: RootState) => state.userView);

  // Local State for Modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // API Hooks
  const {
    handleGetUser,
    userData,
    fetchingUser,
    fetchUserError,
    handleEditUser,
    editError,
    editing,
  } = useUser();
  const { handleFetchAllRoles, rolesData, rolesLoading, rolesError } =
    useFetchRoles();
  const {
    handleFetchCompany,
    companyData,
    fetchingCompany,
    fetchCompanyError,
  } = useCompany();
  const {
    handleFetchCompanyTeams,
    fetchingTeams,
    fetchTeamsError,
    fetchTeamsData,
  } = useFetchCompanyTeams();

  // Effect Hooks (handle api data once loaded)
  useEffect(() => {
    dispatch(checkUrlForUserOption());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      handleGetUser(id);
      handleFetchAllRoles();
    }
  }, [id]);

  useEffect(() => {
    if (userData) {
      dispatch(setUserViewData(userData.data));
      handleFetchCompany(userData.data.primary_companies[0]);
      handleFetchCompanyTeams(userData.data.primary_companies[0]);
    }
  }, [userData]);

  useEffect(() => {
    if (rolesData) {
      dispatch(setUserViewRolesData(rolesData.data));
    }
  }, [rolesData]);

  useEffect(() => {
    if (companyData) {
      dispatch(setUserViewCompanyData(companyData.data));
    }
  }, [companyData]);

  useEffect(() => {
    if (fetchTeamsData) {
      dispatch(setUserViewTeamsData(fetchTeamsData.data));
    }
  }, [fetchTeamsData]);

  // Helper Functions
  const handlePillClick = (option: string): void => {
    dispatch(setUserViewOption(option));
  };

  // Post data
  const onPost = async () => {
    if (id) {
      const payload = {
        firstName: userViewData?.first_name,
        lastName: userViewData?.last_name,
        dob: userViewData?.date_of_birth,
        phone: userViewData?.phone_number,
        jobTitle: userViewData?.job_title,
        roles: userViewData?.roles || [],
        status: userViewData?.status,
        teams: userViewData?.teams || [],
      };

      setIsModalOpen(true);
      await handleEditUser(id, payload);

      dispatch(applyUserViewChanges());
    }
  };

  const onCancelChanges = async () => {
    dispatch(resetUserViewChanges());
  };

  // Map for more readability
  const tabOptionComponents: Record<string, JSX.Element> = {
    main: <UserSingleViewMain />,
    activities: <UserSingleViewActivities />,
    teams: <UserSingleViewTeams />,
    enquiries: <UserSingleViewEnquiries />,
    documents: <UserSingleViewDocuments />,
    notifications: <UserSingleViewNotifications />,
  };

  const isLoading =
    fetchingUser || rolesLoading || fetchingCompany || fetchingTeams;
  const errors = useAxiosErrorMessages([
    fetchUserError,
    rolesError,
    fetchCompanyError,
    fetchTeamsError,
  ]);

  const userFullName = `${originalUserViewData?.first_name} ${originalUserViewData?.last_name}`;
  const userInitials = getInitialsFromName(userFullName);

  // Render User Data and UI
  return (
    <SingleViewLayout isLoading={isLoading} errors={errors}>
      {userData && rolesData && companyData && fetchTeamsData && (
        <>
          <SingleViewHeader title={userFullName} avatarInitials={userInitials}>
            <Chip variant="outlined" label="Broker" />
            <Chip variant="outlined" label={"2 Total Enquiries"} />
            <AddIcon />
            <Chip
              label="1 Active Enquiry"
              variant="filled"
              icon={<InfoIcon sx={{ fontSize: 20 }} />}
            />
          </SingleViewHeader>
          <PillNavigation
            reduxSelector={userOption}
            pillActiveColor="black"
            buttons={userPillData}
            onClick={handlePillClick}
          />
          <Box>{tabOptionComponents[userOption] || null}</Box>
        </>
      )}

      {/* Save and Cancel Buttons */}
      {hasChanges && isValidated && (
        <SaveCancelButtons
          onSave={onPost}
          onCancel={onCancelChanges}
          isSaving={editing}
        />
      )}

      {/* Modal for Save Confirmation */}
      <SaveConfirmationDialog
        isModalOpen={isModalOpen}
        editing={editing}
        editError={editError?.response?.data?.message}
        onConfirm={() => setIsModalOpen(false)}
      ></SaveConfirmationDialog>
    </SingleViewLayout>
  );
};

export default UserSingleViewScreen;
