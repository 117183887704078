import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { UserData, CompanyData, Role, Team } from "./interface/interface";
import { hasStateChanged } from "./helpers/helpers";

interface UserViewState {
  userViewSliceOption: string;
  hasChanges: boolean;
  isValidated: boolean;

  userViewData: UserData | null;
  originalUserViewData: UserData | null;

  userViewRolesData: Role[] | null;
  originalUserViewRolesData: Role[] | null;

  userViewCompanyData: CompanyData | null;
  originalUserViewCompanyData: CompanyData | null;

  userViewTeamsData: Team[] | null;
  originalUserViewTeamsData: Team[] | null;
}

const initialState: UserViewState = {
  userViewSliceOption: "main",
  hasChanges: false,
  isValidated: false,

  userViewData: null,
  originalUserViewData: null,

  userViewRolesData: null,
  originalUserViewRolesData: null,

  userViewCompanyData: null,
  originalUserViewCompanyData: null,

  userViewTeamsData: null,
  originalUserViewTeamsData: null,
};

const userViewSlice = createSlice({
  name: "userView",
  initialState,
  reducers: {
    setUserViewOption: (state, action: PayloadAction<string>) => {
      state.userViewSliceOption = action.payload;
    },

    setUserViewData: (state, action: PayloadAction<UserData>) => {
      state.userViewData = { ...state.userViewData, ...action.payload };
      if (!state.originalUserViewData) {
        state.originalUserViewData = action.payload;
      }
      state.hasChanges = hasStateChanged(
        state.originalUserViewData,
        state.userViewData,
      );
    },

    setUserViewRolesData: (state, action: PayloadAction<Role[]>) => {
      state.userViewRolesData = action.payload;
      if (!state.originalUserViewRolesData) {
        state.originalUserViewRolesData = action.payload;
      }
      state.hasChanges = hasStateChanged(
        state.originalUserViewRolesData,
        state.userViewRolesData,
      );
    },

    setUserViewCompanyData: (state, action: PayloadAction<CompanyData>) => {
      state.userViewCompanyData = action.payload;
      if (!state.originalUserViewCompanyData) {
        state.originalUserViewCompanyData = action.payload;
      }
      state.hasChanges = hasStateChanged(
        state.originalUserViewCompanyData,
        state.userViewCompanyData,
      );
    },

    setUserViewTeamsData: (state, action: PayloadAction<Team[]>) => {
      state.userViewTeamsData = action.payload;
      if (!state.originalUserViewTeamsData) {
        state.originalUserViewTeamsData = action.payload;
      }
      state.hasChanges = hasStateChanged(
        state.originalUserViewTeamsData,
        state.userViewTeamsData,
      );
    },

    resetUserViewChanges: (state) => {
      state.userViewData = state.originalUserViewData;
      state.userViewRolesData = state.originalUserViewRolesData;
      state.userViewCompanyData = state.originalUserViewCompanyData;
      state.userViewTeamsData = state.originalUserViewTeamsData;
      state.hasChanges = false;
    },

    applyUserViewChanges: (state) => {
      state.originalUserViewData = state.userViewData;
      state.originalUserViewRolesData = state.userViewRolesData;
      state.originalUserViewCompanyData = state.userViewCompanyData;
      state.originalUserViewTeamsData = state.userViewTeamsData;
      state.hasChanges = false;
    },

    setIsValidated: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
  },
});

export const checkUrlForUserOption = (): AppThunk => (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search);
  const option = searchParams.get("option");

  if (
    option === "main" ||
    option === "activities" ||
    option === "teams" ||
    option === "enquiries" ||
    option === "documents" ||
    option === "notifications"
  ) {
    dispatch(setUserViewOption(option));
  }
};

export const {
  setUserViewOption,
  setUserViewData,
  setUserViewRolesData,
  setUserViewCompanyData,
  setUserViewTeamsData,
  resetUserViewChanges,
  applyUserViewChanges,
  setIsValidated,
} = userViewSlice.actions;

export default userViewSlice.reducer;
