import React from "react";
import { Box, Chip, useTheme, SxProps, Theme } from "@mui/material";

interface CustomStepperPointerProps {
  icon: React.ReactElement;
  label: string;
  sx?: SxProps<Theme>;
}

const Pointer: React.FC<CustomStepperPointerProps> = ({
  icon,
  label,
  sx = {},
}) => {
  const theme = useTheme();
  const customStepperPointer: SxProps<Theme> = theme.custom?.pointer || {};

  return (
    <Box sx={{ position: "relative" }}>
      <Chip icon={icon} label={label} sx={{ ...customStepperPointer, ...sx }} />
    </Box>
  );
};

export default Pointer;
