import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import NotificationAvatar from "../../../components/NotificationAvatar/NotificationAvatar";
import { CompanyData } from "../../../store/slices/interface/interface";

export const columns: GridColDef<CompanyData>[] = [
  {
    field: "name",
    headerName: "Company",
    width: 180,
    valueGetter: (_, row) => `${row.name || ""}`,
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: (_, row) =>
      `${row.disabled || row.locked ? "Inactive" : "Active"}`,
    renderCell: (params: GridRenderCellParams) =>
      params.value ? (
        <Chip
          label={params.value}
          sx={{
            color: "white",
            backgroundColor: "lightBlue",
            height: 28,
          }}
        />
      ) : null,
  },
  {
    field: "primary_address.address_1",
    headerName: "Address",
    width: 200,
    valueGetter: (_, row) =>
      `${row.primary_address !== null ? row.primary_address?.address_1 : ""}`,
  },
  {
    field: "primary_address.city",
    headerName: "City",
    width: 160,
    valueGetter: (_, row) =>
      `${row.primary_address !== null ? row.primary_address?.city : ""}`,
  },
  {
    field: "primary_address.country",
    headerName: "Country",
    valueGetter: (_, row) =>
      `${row.primary_address !== null ? row.primary_address?.country : ""}`,
  },
  {
    field: "total_enquiries",
    headerName: "Live Enquiries",
    width: 130,
    valueGetter: (_, row) => `${row.total_enquiries}`,
    renderCell: (params: GridRenderCellParams) =>
      params.value ? (
        <NotificationAvatar value={params.value} />
      ) : (
        <NotificationAvatar value="0" />
      ),
  },
  {
    field: "active_enquiries",
    headerName: "Active Enquiries",
    width: 130,
    valueGetter: (_, row) => `${row.active_enquiries}`,
    renderCell: (params: GridRenderCellParams) =>
      params.value ? (
        <NotificationAvatar value={params.value} />
      ) : (
        <NotificationAvatar value="0" />
      ),
  },
  {
    field: "primary_user.email",
    headerName: "Contact Email",
    width: 140,
    valueGetter: (_, row) =>
      `${row.primary_user !== null ? row.primary_user?.email : ""}`,
  },
  {
    field: "primary_user.phone_number",
    headerName: "Phone",
    width: 120,
    valueGetter: (_, row) =>
      `${row.primary_user !== null ? row.primary_user?.phone_number?.replace(/[()\s]+/g, "") : ""}`,
  },
  {
    field: "created_at",
    headerName: "Creation Date",
    width: 200,
    renderCell: (params) => {
      const formattedDate = dayjs(params.value).format("h:mma Do MMM YYYY");
      return <span>{formattedDate}</span>;
    },
  },
];
