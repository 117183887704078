import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ProvideButton from "../Buttons/ProvideButton";
import Grid from "@mui/material/Grid2";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CalendarToday from "@mui/icons-material/CalendarToday";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LaptopIcon from "@mui/icons-material/Laptop";
import MailIcon from "@mui/icons-material/Mail";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const UserSettingsNotifications: React.FC = () => {
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);

  const handleDayToggle = (
    event: React.MouseEvent<HTMLElement>,
    newDays: string[],
  ) => {
    setSelectedDays(newDays);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSwitchOn(event.target.checked);
  };

  return (
    <Grid container direction="column" spacing={4} sx={{ maxWidth: 450 }}>
      <Stack spacing={1}>
        <Typography variant="h4" component="h4">
          Do not Disturb
        </Typography>
        <ProvideButton
          buttontype="notification"
          sx={{
            width: 176,
            height: 30,
          }}
        >
          <NotificationsIcon />
          <Typography variant="caption">PAUSE NOTIFICATIONS</Typography>
        </ProvideButton>
      </Stack>
      <Grid container alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: 44,
            height: 44,
          }}
        >
          <CalendarToday />
        </Avatar>
        <Typography variant="h4" component="h4">
          Schedule
        </Typography>
      </Grid>

      <Grid container alignItems="center" spacing={3}>
        <Switch onChange={handleSwitchChange} />
        <Typography variant="body1">Do not notify me:</Typography>
        <Grid container spacing={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
              label="From"
              defaultValue={dayjs("2022-04-17T15:30")}
              format="hh:mm a"
              disabled={!isSwitchOn}
              sx={{
                width: 96,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: 45,
                },
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
              label="To"
              defaultValue={dayjs("2022-04-17T15:30")}
              format="hh:mm a"
              disabled={!isSwitchOn}
              sx={{
                width: 96,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: 45,
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <Stack spacing={1}>
          <Typography variant="body1">
            Do not disturb me on these days:
          </Typography>
          <ToggleButtonGroup
            value={selectedDays}
            onChange={handleDayToggle}
            aria-label="days of the week"
          >
            {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
              <ToggleButton key={day} value={day} aria-label={day}>
                {day}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      </Box>
      <Grid container alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: 44,
            height: 44,
          }}
        >
          <MailIcon />
        </Avatar>
        <Typography variant="h4" component="h4">
          Email notifications
        </Typography>
      </Grid>

      <FormControl fullWidth variant="outlined">
        <TextField
          id="notification-email"
          label="Email Preference"
          placeholder="john.doe@gmail.com"
          variant="outlined"
          fullWidth
        />
      </FormControl>
      <Stack spacing={2}>
        <Typography variant="h3">Enquiry Activity</Typography>
        <RadioGroup sx={{ pl: 2 }}>
          <Stack spacing={1}>
            <FormControlLabel
              defaultChecked
              value="email-enquiry-real-time"
              control={<Radio />}
              label={<Typography>Real-time</Typography>}
            />
            <FormControlLabel
              value="email-enquiry-summary-am"
              control={<Radio />}
              label={<Typography>Summary (AM & PM)</Typography>}
            />
            <FormControlLabel
              value="email-enquiry-summary-daily"
              control={<Radio />}
              label={<Typography>Summary (Daily)</Typography>}
            />
            <FormControlLabel
              value="email-enquiry-summary-weekly"
              control={<Radio />}
              label={<Typography>Summary (Weekly)</Typography>}
            />
            <FormControlLabel
              value="email-enquiry-summary-never"
              control={<Radio />}
              label={<Typography>Never</Typography>}
            />
          </Stack>
        </RadioGroup>
        <Typography variant="caption">
          Read more about the different notifications
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3">Messages</Typography>
        <RadioGroup sx={{ pl: 2 }}>
          <Stack spacing={1}>
            <FormControlLabel
              defaultChecked
              value="email-messages-real-time"
              control={<Radio />}
              label={<Typography>Real-time</Typography>}
            />
            <FormControlLabel
              value="email-messages-summary-am"
              control={<Radio />}
              label={<Typography>Summary (AM & PM)</Typography>}
            />
            <FormControlLabel
              value="email-messages-summary-daily"
              control={<Radio />}
              label={<Typography>Summary (Daily)</Typography>}
            />
            <FormControlLabel
              value="email-messages-summary-weekly"
              control={<Radio />}
              label={<Typography>Summary (Weekly)</Typography>}
            />
            <FormControlLabel
              value="email-messages-summary-never"
              control={<Radio />}
              label={<Typography>Never</Typography>}
            />
          </Stack>
        </RadioGroup>
      </Stack>

      <Grid container alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: 44,
            height: 44,
          }}
        >
          <WhatsAppIcon />
        </Avatar>
        <Typography variant="h4" component="h4">
          WhatsApp
        </Typography>
      </Grid>

      <FormControl fullWidth variant="outlined">
        <TextField
          id="notification-whatsapp"
          label="Number Preference"
          placeholder="+44 759 333 2124"
          variant="outlined"
          fullWidth
        />
      </FormControl>
      <Stack spacing={2}>
        <Typography variant="h3">Enquiry Activity</Typography>
        <RadioGroup sx={{ pl: 2 }}>
          <Stack spacing={1}>
            <FormControlLabel
              defaultChecked
              value="whatsapp-enquiry-real-time"
              control={<Radio />}
              label={<Typography>Real-time</Typography>}
            />
            <FormControlLabel
              value="whatsapp-enquiry-summary-am"
              control={<Radio />}
              label={<Typography>Summary (AM & PM)</Typography>}
            />
            <FormControlLabel
              value="whatsapp-enquiry-summary-daily"
              control={<Radio />}
              label={<Typography>Summary (Daily)</Typography>}
            />
            <FormControlLabel
              value="whatsapp-enquiry-summary-weekly"
              control={<Radio />}
              label={<Typography>Summary (Weekly)</Typography>}
            />
            <FormControlLabel
              value="whatsapp-enquiry-summary-never"
              control={<Radio />}
              label={<Typography>Never</Typography>}
            />
          </Stack>
        </RadioGroup>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3">Messages</Typography>
        <RadioGroup sx={{ pl: 2, mt: 1 }}>
          <Stack spacing={1}>
            <FormControlLabel
              defaultChecked
              value="whatsapp-messages-real-time"
              control={<Radio />}
              label={<Typography>Real-time</Typography>}
            />
            <FormControlLabel
              value="whatsapp-messages-summary-am"
              control={<Radio />}
              label={<Typography>Summary (AM & PM)</Typography>}
            />
            <FormControlLabel
              value="whatsapp-messages-summary-daily"
              control={<Radio />}
              label={<Typography>Summary (Daily)</Typography>}
            />
            <FormControlLabel
              value="whatsapp-messages-summary-weekly"
              control={<Radio />}
              label={<Typography>Summary (Weekly)</Typography>}
            />
            <FormControlLabel
              value="whatsapp-messages-summary-never"
              control={<Radio />}
              label={<Typography>Never</Typography>}
            />
          </Stack>
        </RadioGroup>
      </Stack>

      <Grid container alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: 44,
            height: 44,
          }}
        >
          <LaptopIcon />
        </Avatar>
        <Typography variant="h4" component="h4">
          Browser notifications
        </Typography>
      </Grid>
      <Stack spacing={2}>
        <Typography variant="h3">Enquiry Activity</Typography>
        <RadioGroup sx={{ pl: 2 }}>
          <Stack spacing={1}>
            <FormControlLabel
              defaultChecked
              value="browser-enquiry-real-time"
              control={<Radio />}
              label={<Typography>Real-time</Typography>}
            />
            <FormControlLabel
              value="browser-enquiry-summary-am"
              control={<Radio />}
              label={<Typography>Summary (AM & PM)</Typography>}
            />
            <FormControlLabel
              value="browser-enquiry-summary-daily"
              control={<Radio />}
              label={<Typography>Summary (Daily)</Typography>}
            />
            <FormControlLabel
              value="browser-enquiry-summary-weekly"
              control={<Radio />}
              label={<Typography>Summary (Weekly)</Typography>}
            />
            <FormControlLabel
              value="browser-enquiry-summary-never"
              control={<Radio />}
              label={<Typography>Never</Typography>}
            />
          </Stack>
        </RadioGroup>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3">Messages</Typography>
        <RadioGroup sx={{ pl: 2 }}>
          <Stack spacing={1}>
            <FormControlLabel
              defaultChecked
              value="browser-messages-real-time"
              control={<Radio />}
              label={<Typography>Real-time</Typography>}
            />
            <FormControlLabel
              value="browser-messages-summary-am"
              control={<Radio />}
              label={<Typography>Summary (AM & PM)</Typography>}
            />
            <FormControlLabel
              value="browser-messages-summary-daily"
              control={<Radio />}
              label={<Typography>Summary (Daily)</Typography>}
            />
            <FormControlLabel
              value="browser-messages-summary-weekly"
              control={<Radio />}
              label={<Typography>Summary (Weekly)</Typography>}
            />
            <FormControlLabel
              value="browser-messages-summary-never"
              control={<Radio />}
              label={<Typography>Never</Typography>}
            />
          </Stack>
        </RadioGroup>
      </Stack>
    </Grid>
  );
};

export default UserSettingsNotifications;
