import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  FormControl,
  OutlinedInput,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  Radio,
  RadioGroup,
  Divider,
  InputAdornment,
  IconButton,
  Stack,
  Box,
  CircularProgress,
} from "@mui/material";
import ProvideButton from "../Buttons/ProvideButton";
import useUserValidateInput from "../../hooks/useUserValidateInput";
import AppleIcon from "../Icons/AppleIcon/AppleIcon";
import GoogleIcon from "../Icons/GoogleIcon/GoogleIcon";
import FacebookIcon from "../Icons/FacebookIcon/FacebookIcon";
import useRegister from "../../hooks/api/useRegister";
import useInvite from "../../hooks/api/useInvite";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "../../hooks/useRedux";
import { login } from "../../store/slices/authSlice";
import useFetchInviteUser from "../../hooks/api/useFetchInviteUser";

interface SignUpProps {
  mode: "sign-up" | "invite";
}

const SignUp: React.FC<SignUpProps> = (props) => {
  const { mode } = props;
  const { id } = useParams<{
    id: string;
  }>();

  const { errors, validateField, validatePassword } = useUserValidateInput();
  const { handleRegister, registering, registerError } = useRegister();
  const { handleInvite, inviting, inviteError } = useInvite(id);
  const {
    handleFetchInviteUser,
    inviteUserData,
    fetchingInviteUser,
    fetchInviteUserError,
  } = useFetchInviteUser(id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    marketingOptIn: false,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [areInputsValidated, setAreInputsValidated] = useState<boolean>(false);

  // Submit handler - takes all data and posts to endpoint
  const handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();

    const result =
      mode === "invite"
        ? await handleInvite(formData)
        : await handleRegister(formData);

    if (result.success) {
      dispatch(login());
      navigate("/dashboard");
    }
  };

  // This is called when data is entered into fields, it
  // validates on keypress, then sets form data to local state
  // field: keyof typeof formData identifies which field
  const handleChange =
    (field: keyof typeof formData) =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;

      if (
        field !== "password" &&
        field !== "confirmPassword" &&
        field !== "marketingOptIn"
      ) {
        if (mode === "invite") {
          validateField(field, value, true);
        } else {
          validateField(field, value);
        }
      }

      setFormData((prev) => ({ ...prev, [field]: value }));
    };

  // Load user data if invite screen
  useEffect(() => {
    if (mode === "invite") {
      handleFetchInviteUser();
    }
  }, [mode]);

  // Set form data
  useEffect(() => {
    if (inviteUserData) {
      setFormData((prev) => ({
        ...prev,
        firstName: inviteUserData.invited_user.first_name || "",
        lastName: inviteUserData.invited_user.last_name || "",
        email: inviteUserData.invited_user.email || "",
      }));
    }
  }, [inviteUserData]);

  // JS password validation when passwords are changed.
  // Logic is isolated in hook - useValidateInput.ts
  useEffect(() => {
    if (formData.password !== "") {
      validatePassword("password", formData.password, formData.confirmPassword);
      validatePassword(
        "confirmPassword",
        formData.confirmPassword,
        formData.password,
      );
    }
  }, [formData.password, formData.confirmPassword]);

  // More validation to check whether we can post.
  useEffect(() => {
    const isFieldValid = (field: string, error: boolean) =>
      !error && field.trim() !== "";

    const emailValid = isFieldValid(formData.email, errors.email?.error);
    const passwordsValid =
      isFieldValid(formData.password, errors.password?.error) &&
      isFieldValid(formData.confirmPassword, errors.confirmPassword?.error);
    const namesValid =
      isFieldValid(formData.firstName, errors.firstName?.error) &&
      isFieldValid(formData.lastName, errors.lastName?.error);

    setAreInputsValidated(emailValid && passwordsValid && namesValid);
  }, [errors, formData]);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);

  const handleCheckboxChange = (
    _event: SyntheticEvent<Element>,
    checked: boolean,
  ) => {
    setFormData((prev) => ({
      ...prev,
      marketingOptIn: checked,
    }));
  };

  if (fetchInviteUserError) {
    return (
      <Box display="flex">
        {fetchInviteUserError && (
          <Typography color="error">
            {fetchInviteUserError.response?.data.message}
          </Typography>
        )}
      </Box>
    );
  }

  if (fetchingInviteUser) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={500}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Stack
            spacing={3}
            justifyContent="space-between"
            direction={{ xs: "column", sm: "row" }}
            sx={{ flex: 1 }}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="first-name"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange("firstName")}
                error={errors.firstName?.error}
              />
              {errors.firstName?.error && (
                <FormHelperText error>
                  {errors.firstName.helperText}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="last-name"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange("lastName")}
                error={errors.lastName?.error}
              />
              {errors.lastName?.error && (
                <FormHelperText error>
                  {errors.lastName.helperText}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="email"
              placeholder="Email Address"
              disabled={mode === "invite"}
              value={formData.email}
              onChange={handleChange("email")}
              error={errors.email?.error}
            />
            {errors.email?.error && (
              <FormHelperText error>{errors.email.helperText}</FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput id="phone" placeholder="Phone" />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="password"
              autoComplete="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={formData.password}
              onChange={handleChange("password")}
              error={errors.password?.error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.password?.error && (
              <FormHelperText error>
                {errors.password.helperText}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              id="confirm-password"
              autoComplete="confirm-password"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange("confirmPassword")}
              error={errors.confirmPassword?.error}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errors.confirmPassword?.error && (
              <FormHelperText error>
                {errors.confirmPassword.helperText}
              </FormHelperText>
            )}
          </FormControl>
          <Stack spacing={3} sx={{ flex: 1 }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="left"
            >
              <Grid size={{ xs: 12, sm: 7 }}>
                <FormControlLabel
                  control={<Checkbox />}
                  onChange={handleCheckboxChange}
                  label={
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      I’m keen to hear about future offers
                    </Typography>
                  }
                />
              </Grid>

              {mode !== "invite" && (
                <Grid size={{ xs: 12, sm: 5 }}>
                  <RadioGroup row>
                    <Grid container spacing={2}>
                      <FormControlLabel
                        value="sms"
                        control={<Radio />}
                        label={<Typography>SMS Verify</Typography>}
                      />
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label={<Typography>Email Verify</Typography>}
                      />
                    </Grid>
                  </RadioGroup>
                </Grid>
              )}
            </Grid>

            <ProvideButton
              submitbtn="true"
              disabled={
                !!(
                  errors.email?.error ||
                  errors.phone?.error ||
                  !areInputsValidated ||
                  registering ||
                  inviting
                )
              }
              variant="contained"
            >
              {registering || inviting
                ? "Creating Account..."
                : "CREATE ACCOUNT"}
            </ProvideButton>

            {(registerError || inviteError) && (
              <Typography color="error">
                {registerError?.response?.data.message ||
                  inviteError?.response?.data.message}
              </Typography>
            )}

            <Divider orientation="horizontal" sx={{ flex: 1 }} />

            <Grid container spacing={2}>
              <Typography>Or register via</Typography>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="left"
              >
                <AppleIcon />
                <GoogleIcon />
                <FacebookIcon />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </form>
    </>
  );
};

export default SignUp;
