import React from "react";

interface DashboardScreenProps {
  infoText: string;
}

const DashboardScreen: React.FC<DashboardScreenProps> = ({ infoText }) => {
  return <>{infoText}</>;
};

export default DashboardScreen;
