import React from "react";

const ProvideLogoBackground: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 429 321"
      preserveAspectRatio="xMaxYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M580.656 191.491L518.797 16.1911C466.631 -131.533 259.676 -143.914 189.836 -3.22406L107.168 163.353C66.9746 244.39 -138.84 555.033 157.054 486.095L407.338 427.568C636.528 374.387 610.587 276.467 580.656 191.491ZM252.265 214.564C218.912 214.564 192.117 187.833 192.117 155.193C192.117 122.553 219.198 95.8215 252.265 95.8215C285.332 95.8215 312.413 122.553 312.413 155.193C312.413 187.833 285.617 214.564 252.265 214.564ZM423.872 214.564C390.52 214.564 363.724 187.833 363.724 155.193C363.724 122.553 390.805 95.8215 423.872 95.8215C456.939 95.8215 484.02 122.553 484.02 155.193C484.02 187.833 456.939 214.564 423.872 214.564Z"
        fill="#0F0D21"
      />
    </svg>
  );
};

export default ProvideLogoBackground;
