import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

interface ResponsiveStepperTabsProps {
  steps: string[];
  currentStep: number;
}

const CustomStepperMobile: React.FC<ResponsiveStepperTabsProps> = ({
  steps,
  currentStep,
}) => {
  const [selectedTab, setSelectedTab] = useState(currentStep);

  useEffect(() => {
    setSelectedTab(currentStep);
  }, [currentStep]);

  return (
    <Box
      sx={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        borderBottom: "1px solid",
        borderColor: "gray.light",
        display: "flex",
      }}
    >
      {steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            flexShrink: 0,
            padding: "8px 16px",
            borderBottom: selectedTab === index ? "2px solid black" : "none",
            color: selectedTab === index ? "black" : "gray.medium",
          }}
        >
          <Typography variant="body2">{step}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CustomStepperMobile;
