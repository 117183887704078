import React from "react";
import { Box, Typography } from "@mui/material";
import ProvideButton from "../Buttons/ProvideButton";
import { useTheme } from "@mui/material/styles";

interface SaveCancelButtonsProps {
  onSave: () => void;
  onCancel: () => void;
  isSaving: boolean;
}

const SaveCancelButtons: React.FC<SaveCancelButtonsProps> = ({
  onSave,
  onCancel,
  isSaving,
}) => {
  const theme = useTheme();
  const { saveChangesBox } = theme.custom;

  return (
    <Box sx={saveChangesBox}>
      <ProvideButton
        onClick={onSave}
        variant="contained"
        disabled={isSaving}
        sx={{ width: 159, height: 42 }}
      >
        <Typography variant="body3">
          {isSaving ? "Saving..." : "SAVE CHANGES"}
        </Typography>
      </ProvideButton>
      <ProvideButton
        onClick={onCancel}
        disabled={isSaving}
        sx={{ width: 104, height: 42 }}
      >
        <Typography variant="body3">CANCEL</Typography>
      </ProvideButton>
    </Box>
  );
};

export default SaveCancelButtons;
