import useAxios from "axios-hooks";

const useFetchCompanyTeams = () => {
  const [
    { loading: fetchingTeams, error: fetchTeamsError, data: fetchTeamsData },
    fetchTeams,
  ] = useAxios(
    {
      method: "GET",
    },
    { manual: true },
  );

  const handleFetchCompanyTeams = async (id: string) => {
    if (!id) return;

    try {
      await fetchTeams({
        url: `/api/v1/companies/${id}/teams`,
      });
    } catch (error) {
      console.error("Fetch request failed:", error);
      return { error };
    }
  };

  return {
    handleFetchCompanyTeams,
    fetchingTeams,
    fetchTeamsError,
    fetchTeamsData,
  };
};

export default useFetchCompanyTeams;
