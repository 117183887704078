import useAxios from "axios-hooks";
import { useState, useCallback } from "react";

interface SchemaType {
  [key: string]: never;
}

const useFetchSchemas = () => {
  const [{ error: schemaError, loading: schemaLoading }, fetchSchema] =
    useAxios({ method: "GET" }, { manual: true, useCache: false });

  const [{ error: uiSchemaError, loading: uiSchemaLoading }, fetchUiSchema] =
    useAxios({ method: "GET" }, { manual: true, useCache: false });

  const [schemasData, setSchemasData] = useState<{
    schema?: SchemaType;
    uiSchema?: SchemaType;
  }>({});

  const handleFetchSchemas = async (
    schemaName?: string,
    uiSchemaName?: string,
  ) => {
    resetSchemas();

    try {
      const [schemaResponse, uiSchemaResponse] = await Promise.all([
        fetchSchema({ url: `/schemas/${schemaName}` }),
        fetchUiSchema({ url: `/schemas/${uiSchemaName}` }),
      ]);

      setSchemasData({
        schema: schemaResponse.data,
        uiSchema: uiSchemaResponse.data,
      });

      return { schema: schemaResponse.data, uiSchema: uiSchemaResponse.data };
    } catch (error) {
      console.error("Fetch request failed:", error);
      return { error };
    }
  };

  const resetSchemas = useCallback(() => {
    setSchemasData({});
  }, []);

  return {
    handleFetchSchemas,
    schemasData,
    schemasError: schemaError || uiSchemaError,
    schemasLoading: schemaLoading || uiSchemaLoading,
  };
};

export default useFetchSchemas;
