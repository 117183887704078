import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import LiveInfoCard from "../LiveInfoCard/LiveInfoCard";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import {
  Address,
  EditCompanyPayload,
} from "../../store/slices/interface/interface";
import {
  setCompanyViewData,
  setIsValidated,
} from "../../store/slices/companyViewSlice";
import CompanyDetailsFields from "../CompanyDetailsFields/CompanyDetailsFields";
import AddressComponent from "../AddressComponent/AddressComponent";

const CompanySingleViewMain: React.FC = () => {
  const dispatch = useAppDispatch();

  const companyData = useAppSelector(
    (state: RootState) => state.companyView.companyViewData,
  );

  const handleValidatedData = (data: EditCompanyPayload) => {
    if (companyData === null) {
      return;
    }

    const editedData = {
      ...companyData,
      ...data,
    };
    dispatch(setCompanyViewData(editedData));
  };

  const handleAddressChange = (address: Address) => {
    if (!companyData) return;

    const editedData = {
      ...companyData,
      primary_address: address,
    };

    dispatch(setCompanyViewData(editedData));
  };

  const handleToggleChanged = (id: string, checked: boolean) => {
    console.log("Toggle changed", id, checked);
  };

  const liveInfoToggles = [
    {
      id: "active",
      label: "Company Active",
      checked: false,
    },
  ];

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={4}
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ mb: 10 }}
    >
      <Stack spacing={6} sx={{ maxWidth: 480 }}>
        <Stack spacing={4}>
          <Typography variant="h4" component="h4">
            Company Details
          </Typography>
          <CompanyDetailsFields
            formData={companyData}
            onFormDataChange={handleValidatedData}
            onValidated={(validated) => dispatch(setIsValidated(validated))}
          />
        </Stack>
        <Stack spacing={4}>
          <Typography variant="h4" component="h4">
            Address
          </Typography>
          <Box>
            <AddressComponent
              address={companyData?.primary_address}
              onChange={handleAddressChange}
            ></AddressComponent>
          </Box>
        </Stack>
      </Stack>
      <Box sx={{ alignSelf: "flex-start", maxWidth: 352 }}>
        <LiveInfoCard
          createdText={dayjs(companyData?.created_at).format("YYYY-MM-DD")}
          lastActiveText="2024-10-11"
          toggles={liveInfoToggles}
          onToggleChange={handleToggleChanged}
        />
      </Box>
    </Stack>
  );
};

export default CompanySingleViewMain;
