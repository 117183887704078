import React from "react";
import { Switch, SwitchProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/system";

interface ProvideSwitchProps extends SwitchProps {
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}

const ProvideSwitch: React.FC<ProvideSwitchProps> = ({
  size = "medium",
  sx = {},
  ...props
}) => {
  const theme = useTheme();
  const { switchVariants } = theme.custom;

  return (
    <Switch {...props} sx={{ ...switchVariants[size], ...(sx as object) }} />
  );
};

export default ProvideSwitch;
