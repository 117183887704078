import React from "react";
import { Box, Radio, Typography } from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

interface CustomRadioButtonProps {
  title: string;
  svgComponent: React.ReactNode;
  onChange?: () => void;
  disabled?: boolean;
  selected?: boolean;
  containerSx?: object;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  title,
  svgComponent,
  onChange,
  disabled = false,
  selected,
  containerSx,
}) => {
  return (
    <Box
      onClick={onChange}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: 215,
        height: 140,
        width: "100%",
        border: "1px solid #ddd",
        borderRadius: "20px",
        cursor: "pointer",
        padding: 2,
        ...containerSx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          width: "100%",
        }}
      >
        <Radio
          checked={selected}
          onChange={onChange}
          disabled={disabled}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
        />
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {svgComponent}
      </Box>
    </Box>
  );
};

export default CustomRadioButton;
