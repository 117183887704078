import React, { useEffect, useState } from "react";

// UI Components
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PlainTextButton from "../../components/Buttons/PlainTextButton";
import ModalContainer from "../../components/Modal/ModalContainer";
import DropShadowBox from "../../components/DropShadowBox/DropShadowBox";
import ProvideButton from "../../components/Buttons/ProvideButton";

// Icons
import UserIcon from "../../components/Icons/UserIcon/UserIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckmarkCircle from "../../components/Icons/CheckmarkCircle/CheckmarkCircle";
import RedCrossIcon from "../../components/Icons/RedCrossIcon/RedCrossIcon";
import { steps } from "./data/stepData";
import CustomStepperDesktop from "../../components/CustomStepper/CustomStepperDesktop";
import CustomStepperMobile from "../../components/CustomStepper/CustomStepperMobile";

// Styled Components
import { AnimatedBounceContainer } from "../../styled/AnimatedBounceContainer.styled";

// Custom Hooks
import useFetchCompanies from "../../hooks/api/useFetchCompanies";
import useFetchRoles from "../../hooks/api/useFetchRoles";
import useCreateUser from "../../hooks/api/useCreateUser";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";

// Redux Actions & Store
import {
  setAddUserData,
  setCompaniesData,
  setRolesData,
  setSelectedCompanyId,
} from "../../store/slices/addUserSlice";
import { RootState } from "../../store/store";

// Form Steps Components
import AddUserContactDetails from "../../components/AddUser/AddUserContactDetails";
import AddUserCompanyRoles from "../../components/AddUser/AddUserCompanyRoles";
import AddUserTeams from "../../components/AddUser/AddUserTeams";
import AddUserSendLogin from "../../components/AddUser/AddUserSendLogin";

// Enum for Steps
enum Step {
  ContactDetails = "contactDetails",
  CompanyRoles = "companyRoles",
  Teams = "teams",
  SendLogin = "sendLogin",
}

const stepIndexMap: Record<Step, number> = {
  [Step.ContactDetails]: 0,
  [Step.CompanyRoles]: 1,
  [Step.Teams]: 2,
  [Step.SendLogin]: 3,
};

const AddUserScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { ContactDetails, CompanyRoles, Teams, SendLogin } = Step;

  // Redux State Selectors
  const userDataRedux = useAppSelector(
    (state: RootState) => state.addUser.addUserData,
  );
  const selectedCompanyId = useAppSelector(
    (state: RootState) => state.addUser.selectedCompanyId,
  );

  // API Hooks
  const { handleFetchCompanies, companyList, companyLoading, companyError } =
    useFetchCompanies();
  const { handleFetchAllRoles, rolesData, rolesLoading, rolesError } =
    useFetchRoles();
  const { handleCreateUser, creatingUser, createUserError } = useCreateUser();

  // Local State
  const [step, setStep] = useState<Step>(ContactDetails);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [stepIndex, setStepIndex] = useState(0);

  // Fetch companies and roles on mount
  useEffect(() => {
    handleFetchCompanies();
    handleFetchAllRoles();
  }, []);

  // Store fetched companies in Redux
  useEffect(() => {
    setStepIndex(stepIndexMap[step]);
  }, [step]);

  useEffect(() => {
    if (companyList) {
      dispatch(setCompaniesData(companyList.data));
      dispatch(setSelectedCompanyId(companyList.data[0].id));
    }
  }, [companyList]);

  // Store fetched roles in Redux
  useEffect(() => {
    if (rolesData) {
      dispatch(setRolesData(rolesData.data));
    }
  }, [rolesData]);

  // Step Navigation Handler
  const changeStep = (nextStep: string) => {
    if (Object.values(Step).includes(nextStep as Step)) {
      setStep(nextStep as Step);
    }
  };

  // Submit User Data
  const postData = async () => {
    handleCreateUser(
      userDataRedux?.first_name || "",
      userDataRedux?.last_name || "",
      userDataRedux?.email || "",
      userDataRedux?.phone_number || "",
      userDataRedux?.date_of_birth || "",
      selectedCompanyId || "",
      userDataRedux?.roles || [],
      userDataRedux?.teams || [],
    );
    setIsModalOpen(true);
  };

  // Reset User Data
  const deleteUser = () => {
    dispatch(
      setAddUserData({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        date_of_birth: "",
        roles: [],
        teams: [],
      }),
    );
    dispatch(setSelectedCompanyId(companyList.data[0].id));
    changeStep(ContactDetails);
  };

  const handleDoneButton = () => {
    deleteUser();
    setIsModalOpen(false);
  };

  // Loading State
  if (companyLoading || rolesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  // Error State
  if (companyError || rolesError) {
    return (
      <>
        {companyError && (
          <Typography color="error">
            {companyError.response?.data.message}
          </Typography>
        )}
        {rolesError && (
          <Typography color="error">
            {rolesError.response?.data.message}
          </Typography>
        )}
      </>
    );
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="end" spacing={3}>
        <PlainTextButton onClick={deleteUser}>
          <DeleteIcon /> DELETE
        </PlainTextButton>
      </Grid>
      <Stack spacing={{ xs: 0, md: 5 }}>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          flexDirection="row"
          sx={{ flexWrap: "wrap", justifyContent: "flex-start" }}
        >
          <UserIcon />
          <Typography component="h1" variant="h1" color="black">
            Create User
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row-reverse" }}
          gap={5}
        >
          <Box
            minWidth={280}
            sx={{
              display: { xs: "none", md: "block" },
              mx: { xs: 0, md: 10 },
            }}
          >
            <CustomStepperDesktop
              title="Create New User"
              steps={steps}
              currentStep={stepIndex}
            />
          </Box>
          <Box sx={{ display: { xs: "inline-flex", md: "none" }, mt: 1 }}>
            <CustomStepperMobile steps={steps} currentStep={stepIndex} />
          </Box>

          <Box
            flex={{ xs: "unset", md: 2 }}
            display="flex"
            flexDirection="column"
          >
            {step === ContactDetails && (
              <AddUserContactDetails onStep={changeStep} />
            )}
            {step === CompanyRoles && (
              <AddUserCompanyRoles onStep={changeStep} />
            )}
            {step === Teams && <AddUserTeams onStep={changeStep} />}
            {step === SendLogin && (
              <AddUserSendLogin onStep={changeStep} onFormSubmit={postData} />
            )}
          </Box>
        </Box>
      </Stack>
      <ModalContainer isOpen={isModalOpen}>
        <DropShadowBox minWidth={200}>
          <Stack alignItems="center" spacing={2}>
            <AnimatedBounceContainer>
              {creatingUser ? (
                <CircularProgress color="primary" />
              ) : createUserError ? (
                <RedCrossIcon />
              ) : (
                <CheckmarkCircle />
              )}
            </AnimatedBounceContainer>
            <Typography textAlign="center">
              {creatingUser
                ? "Creating user..."
                : createUserError
                  ? createUserError?.response?.data?.message ||
                    "An error occurred."
                  : `Login details sent to: ${userDataRedux?.email}`}
            </Typography>
            <ProvideButton
              variant="contained"
              onClick={handleDoneButton}
              sx={{ width: 157 }}
              disabled={creatingUser}
            >
              <strong>DONE</strong>
            </ProvideButton>
          </Stack>
        </DropShadowBox>
      </ModalContainer>
    </>
  );
};

export default AddUserScreen;
