import { useEffect, useRef } from "react";

interface Title {
  id: string;
}

const useScrollListener = (
  titles: Title[],
  setSelectedTitleIndex: (index: number) => void,
) => {
  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const setSectionRef = (id: string) => (element: HTMLDivElement | null) => {
    sectionRefs.current[id] = element;
  };

  const handleScroll = () => {
    Object.entries(sectionRefs.current).forEach(([id, sectionRef]) => {
      if (sectionRef) {
        const rect = sectionRef.getBoundingClientRect();
        const elementTop = rect.top;
        const elementBottom = rect.bottom;
        const elementHeight = rect.height;

        const threshold = elementHeight * 0.4;
        const isVisible =
          elementBottom > threshold &&
          elementTop < window.innerHeight - threshold;

        if (isVisible) {
          const index = titles.findIndex((title) => title.id === id);
          if (index !== -1) {
            setSelectedTitleIndex(index);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (titles.length > 0) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [titles]);

  return { setSectionRef };
};

export default useScrollListener;
