import React from "react";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { Role } from "../../store/slices/interface/interface";

interface UserRolesListProps {
  rolesList: Role[];
  selectedRoles?: string[];
  onRolesChange: (updatedRoles: string[]) => void;
}

const UserRolesList: React.FC<UserRolesListProps> = ({
  rolesList,
  selectedRoles = [],
  onRolesChange,
}) => {
  const handleToggle = (roleName: string) => {
    const updatedRoles = selectedRoles.includes(roleName)
      ? selectedRoles.filter((name) => name !== roleName)
      : [...selectedRoles, roleName];

    onRolesChange(updatedRoles);
  };

  return (
    <Stack spacing={2} sx={{ maxWidth: 293 }}>
      {rolesList.map(({ name, uuid }) => (
        <Box
          key={uuid}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>{name}</Typography>
          <Switch
            checked={selectedRoles.includes(name)}
            onChange={() => handleToggle(name)}
          />
        </Box>
      ))}
    </Stack>
  );
};

export default UserRolesList;
