import React, { useEffect } from "react";
import { Box, Chip, CircularProgress, Stack, Typography } from "@mui/material";
import PillNavigation from "../../components/PillNavigation/PillNavigation";
import { settingsPillData } from "./data/settingsData";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  checkUrlForSettingsOption,
  setSettingsOption,
} from "../../store/slices/settingsSlice";
import { RootState } from "../../store/store";
import UserTopNavigation from "../../components/UserTopNavigation/UserTopNavigation";
import UserSettingsMain from "../../components/UserSettings/UserSettingsMain";
import UserTeams from "../../components/UserTeams/UserTeams";
import UserSettingsNotifications from "../../components/UserSettings/UserSettingsNotifications";
import useFetchCompanyTeams from "../../hooks/api/useFetchCompanyTeams";

const UserSettingsScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const settingsOption = useAppSelector(
    (state: RootState) => state.settings.settingsOption,
  );
  const companyId = useAppSelector(
    (state: RootState) => state.settings.userData?.primary_companies?.[0],
  );

  const {
    handleFetchCompanyTeams,
    fetchingTeams,
    fetchTeamsError,
    fetchTeamsData,
  } = useFetchCompanyTeams();

  useEffect(() => {
    dispatch(checkUrlForSettingsOption());
  }, [dispatch]);

  useEffect(() => {
    if (companyId) {
      handleFetchCompanyTeams(companyId);
    }
  }, []);

  function handlePillClick(option: string): void {
    dispatch(setSettingsOption(option));
  }

  if (fetchingTeams) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (fetchTeamsError) {
    return (
      <Typography color="error">
        {fetchTeamsError.response?.data.message}
      </Typography>
    );
  }

  return (
    <Stack spacing={4}>
      <UserTopNavigation />
      <Typography component="h1" variant="h1" color="secondary.main">
        My User Settings <Chip variant="outlined" label="Broker" />
      </Typography>
      <PillNavigation
        reduxSelector={settingsOption}
        pillActiveColor="black"
        buttons={settingsPillData}
        onClick={handlePillClick}
      />

      {settingsOption === "main" ? (
        <UserSettingsMain />
      ) : settingsOption === "teams" ? (
        <UserTeams
          teamsData={fetchTeamsData.data}
          teamsTitle="Yellow Stone Finance"
          selectedIds={["9d76523d-729e-49fd-8ba9-dd3995bcaf5c"]}
        />
      ) : settingsOption === "notifications" ? (
        <UserSettingsNotifications />
      ) : null}
    </Stack>
  );
};

export default UserSettingsScreen;
