import React, { useState, KeyboardEvent } from "react";
import {
  Typography,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Box,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import ProvideButton from "../Buttons/ProvideButton";
import useUserValidateInput from "../../hooks/useUserValidateInput";
import { useTheme } from "@mui/material/styles";
import { socialButtons } from "./data/socialButtonData";

interface EmailPhoneInputProps {
  emailOrPhone: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContinue: () => void;
}

const EmailPhoneInput: React.FC<EmailPhoneInputProps> = (props) => {
  const theme = useTheme();
  const { iconButton } = theme.custom;
  const { emailOrPhone, onChange, onContinue } = props;
  const { errors, validateEmailOrPhone } = useUserValidateInput();

  const [isInputValid, setIsInputValid] = useState<boolean>(false);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const validationResult = validateEmailOrPhone(evt.target.value);
    setIsInputValid(validationResult);
    onChange(evt);
  };

  return (
    <Stack spacing={6}>
      <Grid container spacing={3}>
        {socialButtons.map(({ icon, label, onClick }, index) => (
          <ProvideButton key={index} onClick={onClick} variant="outlined">
            <Box sx={iconButton}>{icon}</Box>
            {label}
          </ProvideButton>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Typography variant="h4" component="p">
          Or Email / Phone
        </Typography>

        <FormControl fullWidth variant="outlined">
          <OutlinedInput
            id="outlined-email-phone"
            placeholder="your@email.com / +447739477105"
            value={emailOrPhone}
            onChange={handleChange}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter" && isInputValid) {
                onContinue();
              }
            }}
            error={errors.general.error}
          />
          {errors.general.error && (
            <FormHelperText error>{errors.general.helperText}</FormHelperText>
          )}
        </FormControl>

        <Grid size={{ xs: 12, md: 8 }}>
          <ProvideButton
            onClick={onContinue}
            disabled={!isInputValid}
            variant="contained"
          >
            CONTINUE
          </ProvideButton>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EmailPhoneInput;
