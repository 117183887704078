import React, { JSX, useEffect, useState } from "react";
import SingleViewLayout from "../../components/SingleViewLayout/SingleViewLayout";
import useCompany from "../../hooks/api/useCompany";
import useFetchCompanyTeams from "../../hooks/api/useFetchCompanyTeams";
import { useParams } from "react-router";
import { Box } from "@mui/material";

// Redux Store & State Management
import { RootState } from "../../store/store";
import {
  setCompanyViewOption,
  setCompanyViewData,
  resetCompanyViewChanges,
  applyCompanyViewChanges,
  checkUrlForCompanyOption,
} from "../../store/slices/companyViewSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";

// Data & Types
import { companyPillData } from "./data/companyData";
import SaveCancelButtons from "../../components/SaveCancelBox/SaveCancelButtons";
import useAxiosErrorMessages from "../../hooks/useAxiosErrorMessages";
import SingleViewHeader from "../../components/SingleViewLayout/SingleViewHeader";
import PillNavigation from "../../components/PillNavigation/PillNavigation";
import SaveConfirmationDialog from "../../components/SaveConfirmationDialog/SaveConfirmationDialog";
import { getInitialsFromName } from "../../utils/helper";
import CompanySingleViewMain from "../../components/CompanySingleViewMain/CompanySingleViewMain";

function CompanySingleViewScreen() {
  const { id } = useParams<{ id: string }>();

  // Dispatch and Redux Selectors
  const dispatch = useAppDispatch();

  const {
    hasChanges,
    isValidated,
    originalCompanyViewData,
    companyViewData,
    companyViewSliceOption: companyOption,
  } = useAppSelector((state: RootState) => state.companyView);

  // Local State for Modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    handleFetchCompany,
    companyData,
    fetchingCompany,
    fetchCompanyError,
    handleEditCompany,
    editing,
    editError,
  } = useCompany();
  const { handleFetchCompanyTeams, fetchingTeams, fetchTeamsError } =
    useFetchCompanyTeams();

  // Effect Hooks (handle api data once loaded)
  useEffect(() => {
    dispatch(checkUrlForCompanyOption());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      handleFetchCompany(id);
      handleFetchCompanyTeams(id);
    }
  }, [id]);

  useEffect(() => {
    if (companyData) {
      dispatch(setCompanyViewData(companyData.data));
    }
  }, [companyData]);

  // Helper Functions
  const handlePillClick = (option: string): void => {
    dispatch(setCompanyViewOption(option));
  };

  const isLoading = fetchingCompany || fetchingTeams;
  const errors = useAxiosErrorMessages([fetchCompanyError, fetchTeamsError]);

  const onPost = async () => {
    if (id) {
      if (!companyViewData) {
        return;
      }

      const payload = {
        name: companyViewData.name,
        company_type: companyViewData.company_type,
        phone_number: companyViewData.phone_number,
        number: companyViewData.number,
        fca_number: companyViewData.fca_number,
        incorporation_date: companyViewData.incorporation_date,
        addresses: companyViewData.addresses,
      };

      if (companyViewData.primary_address) {
        payload.addresses = [companyViewData.primary_address];
      }

      setIsModalOpen(true);
      await handleEditCompany(id, payload);

      dispatch(applyCompanyViewChanges());
    }
  };

  const onCancelChanges = async () => {
    dispatch(resetCompanyViewChanges());
  };

  const tabOptionComponents: Record<string, JSX.Element> = {
    main: <CompanySingleViewMain />,
  };

  const companyLogo = companyViewData?.logo;
  const companyName = originalCompanyViewData?.name || "Company";
  const companyInitials = getInitialsFromName(companyName);

  return (
    <SingleViewLayout isLoading={isLoading} errors={errors}>
      <SingleViewHeader
        title={companyName}
        avatarInitials={companyInitials}
        avatarSrc={companyLogo}
      ></SingleViewHeader>
      {/* Pill Navigation for Different Views */}
      <PillNavigation
        reduxSelector={companyOption}
        pillActiveColor="black"
        buttons={companyPillData}
        onClick={handlePillClick}
      />
      <Box>{tabOptionComponents[companyOption] || null}</Box>

      {/* Save and Cancel Buttons */}
      {hasChanges && isValidated && (
        <SaveCancelButtons
          onSave={onPost}
          onCancel={onCancelChanges}
          isSaving={editing}
        />
      )}

      {/* Modal for Save Confirmation */}
      <SaveConfirmationDialog
        isModalOpen={isModalOpen}
        editing={editing}
        editError={editError?.response?.data?.message}
        onConfirm={() => setIsModalOpen(false)}
      ></SaveConfirmationDialog>
    </SingleViewLayout>
  );
}

export default CompanySingleViewScreen;
