import React, { useEffect } from "react";
import { FormControl, TextField, FormHelperText } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import useUserValidateInput from "../../hooks/useUserValidateInput";
import Grid from "@mui/material/Grid2";
import { UserData } from "../../store/slices/interface/interface";

interface UserContactFieldsProps {
  formData: UserData | null;
  emailDisabled: boolean;
  editedData: (data: UserData) => void;
  isValidated?: (validate: boolean) => void;
}

const UserContactFields: React.FC<UserContactFieldsProps> = ({
  formData,
  emailDisabled = false,
  editedData,
  isValidated,
}) => {
  const { errors, validateField } = useUserValidateInput();

  useEffect(() => {
    if (isValidated) {
      const hasErrors = Object.values(errors).some((error) => error?.error);
      isValidated(!hasErrors);
    }
  }, [errors, isValidated]);

  const handleFormChange =
    (field: "first_name" | "last_name" | "phone_number" | "email") =>
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;

      validateField(field, value, field === "phone_number");
      editedData({ ...formData, [field]: value });
    };

  const handleDateChange = (newDate: Dayjs | null) => {
    editedData({
      ...formData,
      date_of_birth: newDate
        ? dayjs(newDate).format("YYYY-MM-DDTHH:mm:ss")
        : "",
    });
  };

  return (
    <>
      <FormControl fullWidth variant="outlined">
        <TextField
          id="email"
          autoComplete="email"
          label="Email Address"
          value={formData?.email || ""}
          variant="outlined"
          onChange={handleFormChange("email")}
          placeholder="john.doe@gmail.com"
          disabled={emailDisabled}
          fullWidth
        />
        {errors.email?.error && (
          <FormHelperText error>{errors.email.helperText}</FormHelperText>
        )}
      </FormControl>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="first-name"
              autoComplete="first-name"
              label="First Name"
              placeholder="John"
              variant="outlined"
              value={formData?.first_name || ""}
              onChange={handleFormChange("first_name")}
              error={errors.first_name?.error}
              fullWidth
            />
            {errors.first_name?.error && (
              <FormHelperText error>
                {errors.first_name.helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="last-name"
              autoComplete="family-name"
              label="Last Name"
              placeholder="Doe"
              variant="outlined"
              value={formData?.last_name || ""}
              onChange={handleFormChange("last_name")}
              error={errors.last_name?.error}
              fullWidth
            />
            {errors.last_name?.error && (
              <FormHelperText error>
                {errors.last_name.helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              value={
                formData?.date_of_birth ? dayjs(formData.date_of_birth) : null
              }
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  sx: { width: "100%" },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth variant="outlined">
            <TextField
              id="phone"
              autoComplete="phone"
              label="Phone Number"
              value={formData?.phone_number || ""}
              placeholder="+44 759 333 2124"
              variant="outlined"
              onChange={handleFormChange("phone_number")}
              error={errors.phone_number?.error}
              fullWidth
            />
            {errors.phone_number?.error && (
              <FormHelperText error>
                {errors.phone_number.helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default UserContactFields;
