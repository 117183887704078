import React, { useEffect, useState } from "react";

// Material-UI components
import {
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// Custom components
import UserContactFields from "../UserContactFields/UserContactFields";
import UserRolesList from "../UserRolesList/UserRolesList";
import LiveInfoCard from "../LiveInfoCard/LiveInfoCard";

// Hooks
import useUserValidateInput from "../../hooks/useUserValidateInput";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";

// Utilities
import dayjs from "dayjs";

// Redux store and actions
import { RootState } from "../../store/store";
import { UserData } from "../../store/slices/interface/interface";
import {
  setUserViewData,
  setIsValidated,
} from "../../store/slices/userViewSlice";

const UserSingleViewMain: React.FC = () => {
  const dispatch = useAppDispatch();

  // Redux State Selectors
  const userDataRedux = useAppSelector(
    (state: RootState) => state.userView.userViewData,
  );
  const rolesDataRedux = useAppSelector(
    (state: RootState) => state.userView.userViewRolesData,
  );
  const companyDataRedux = useAppSelector(
    (state: RootState) => state.userView.userViewCompanyData,
  );

  const { errors, validateField } = useUserValidateInput();
  const [localJobTitle, setLocalJobTitle] = useState<string | null>("");

  // Sync local job title with Redux state
  useEffect(() => {
    setLocalJobTitle(userDataRedux?.job_title || "");
  }, [userDataRedux]);

  // Dispatch validation status based on job title errors
  useEffect(() => {
    dispatch(setIsValidated(!errors.jobTitle?.error));
  }, [errors]);

  // Handle validated data for user contact and profile updates
  const handleValidatedData = (data: UserData) => {
    const { first_name, last_name, email, phone_number, date_of_birth } = data;
    dispatch(
      setUserViewData({
        first_name,
        last_name,
        email,
        phone_number,
        date_of_birth,
      }),
    );
  };

  // Handle role updates
  const handleRolesChange = (data: string[]) => {
    dispatch(setUserViewData({ roles: data }));
  };

  // Handle job title changes
  const handleJobTitleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value.trim() || null;
    if (value) validateField("jobTitle", value, true);
    dispatch(setUserViewData({ job_title: value }));
    setLocalJobTitle(value);
  };

  // Handle user active status change
  const handleUserActive = (active: string) => {
    dispatch(setUserViewData({ status: active }));
  };

  // Handle user verification (currently just logs for debugging)
  const handleUserVerified = (active: boolean) => {
    console.debug(active);
  };

  const handleToggleChanged = (id: string, checked: boolean) => {
    if (id === "active") {
      handleUserActive(checked ? "active" : "inactive");
    }
    if (id === "verified") {
      handleUserVerified(checked);
    }
  };

  const liveInfoToggles = [
    {
      id: "active",
      label: "User Active",
      checked: userDataRedux?.status === "active",
    },
    { id: "verified", label: "User Verified", checked: false },
  ];

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={4}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {/* Left Section: User Contact and Roles */}
        <Stack spacing={6} sx={{ maxWidth: 480 }}>
          {/* Contact Information */}
          <Stack spacing={4}>
            <Typography variant="h4" component="h4">
              Contacts
            </Typography>
            <UserContactFields
              formData={userDataRedux}
              emailDisabled={true}
              editedData={handleValidatedData}
              isValidated={(validated) => dispatch(setIsValidated(validated))}
            />
          </Stack>

          {/* Company and Job Role */}
          <Stack spacing={4}>
            <Typography variant="h4" component="h4">
              Company & Roles
            </Typography>
            <FormControl variant="outlined">
              <TextField
                id="company"
                label="Company"
                placeholder="Company Name"
                disabled
                value={companyDataRedux?.name ?? ""}
              />
            </FormControl>
            <FormControl variant="outlined">
              <TextField
                id="role"
                label="Job Role"
                placeholder="Job Role"
                value={localJobTitle || ""}
                onChange={handleJobTitleChange}
                error={errors.jobTitle?.error}
              />
              {errors.jobTitle?.error && (
                <FormHelperText error>
                  {errors.jobTitle.helperText}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>

          {/* User Roles */}
          <Stack spacing={2}>
            <Typography variant="h4" component="h4">
              User Roles
            </Typography>
            <UserRolesList
              rolesList={rolesDataRedux ?? []}
              selectedRoles={userDataRedux?.roles}
              onRolesChange={handleRolesChange}
            />
            <Box sx={{ height: { xs: 0, md: 100 } }}></Box>
          </Stack>
        </Stack>

        {/* Right Section: Live Info Card */}
        <Box sx={{ alignSelf: "flex-start", maxWidth: 352 }}>
          <LiveInfoCard
            createdText={dayjs(userDataRedux?.created_at).format("YYYY-MM-DD")}
            lastActiveText="2024-10-11"
            toggles={liveInfoToggles}
            onToggleChange={handleToggleChanged}
          />
        </Box>
      </Stack>
    </>
  );
};

export default UserSingleViewMain;
