import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import { Box, Chip, Typography } from "@mui/material";

interface ProductCategory {
  name: string;
}

interface ProductRow {
  name: string;
  product_category: ProductCategory;
  status: string;
  minimum_loan_amount: string;
  maximum_loan_amount: string;
  match_count: string;
  default_team: string;
  tags: string[];
}

export const productColumns: GridColDef<ProductRow>[] = [
  {
    field: "name",
    headerName: "Lending Option",
    width: 180,
    renderCell: (params) => {
      return <strong>{params.value}</strong>;
    },
  },
  {
    field: "product_category",
    headerName: "Funding Type",
    width: 140,
    renderCell: ({ row }) => {
      const isBusiness = row.product_category.name === "Business";
      return (
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          color="gray.dark"
          height="100%"
        >
          {isBusiness ? <ApartmentRoundedIcon /> : <HouseRoundedIcon />}
          <Typography variant="body2">{row.product_category.name}</Typography>
        </Box>
      );
    },
  },
  {
    field: "status",
    headerName: "Option Status",
    width: 130,
    renderCell: (params: GridRenderCellParams) => {
      const status = params.value as
        | "active"
        | "disabled"
        | "draft"
        | undefined;
      if (!status) return null;

      const formattedStatus = status.replace(/^./, (char) =>
        char.toUpperCase(),
      );

      const chipColours: Record<"active" | "disabled" | "draft", string> = {
        active: "lightBlue",
        disabled: "warning.main",
        draft: "gray.light",
      };

      const textColours: Record<"active" | "disabled" | "draft", string> = {
        active: "white",
        disabled: "white",
        draft: "black",
      };

      return (
        <Chip
          label={formattedStatus}
          variant="filled"
          sx={{
            backgroundColor: chipColours[status],
            color: textColours[status],
          }}
        />
      );
    },
  },
  {
    field: "loan_amount",
    headerName: "Funding Range",
    width: 180,
    valueGetter: (_, row) => {
      const minLoanAmount = row.minimum_loan_amount;
      const maxLoanAmount = row.maximum_loan_amount;

      if (!minLoanAmount && !maxLoanAmount) return "";
      return `£${minLoanAmount.toLocaleString()} - £${maxLoanAmount.toLocaleString()}`;
    },
    renderCell: (params: GridRenderCellParams) =>
      params.value ? (
        <Chip
          label={params.value}
          variant="filled"
          sx={{
            backgroundColor: "gray.light",
            color: "black",
          }}
        />
      ) : null,
  },
  {
    field: "match_count",
    headerName: "Leads Received",
    width: 130,
    renderCell: () => (
      <Chip
        label="156"
        variant="filled"
        sx={{
          backgroundColor: "gray.light",
          color: "black",
        }}
      />
    ),
  },
  {
    field: "default_team",
    headerName: "Default Team",
    width: 130,
    valueGetter: () => "Business Team",
  },
  {
    field: "tags",
    headerName: "Tags",
    width: 180,
    renderCell: () => (
      <Chip
        label="Category Tags"
        variant="outlined"
        sx={{
          backgroundColor: "white",
        }}
      />
    ),
  },
];
