import { useState } from "react";

export type ValidationRule =
  | "email"
  | "phone"
  | "text"
  | "alphanumeric"
  | "password"
  | "matchingField";

const useValidateInput = () => {
  const [errors, setErrors] = useState<
    Record<string, { error: boolean; helperText: string }>
  >({});

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^(?:\+44|0)(7\d{9}|[1-9]\d{1,4}[ ]?\d{4,8})$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{12,}$/;
  const textRegex = /^[A-Za-z\s'-]+$/;
  const alphanumericRegex = /^[A-Za-z0-9\s'-]+$/;

  const emailCheck = (value: string) => emailRegex.test(value);
  const phoneCheck = (value: string, ignoreEmptyString: boolean) => {
    if (ignoreEmptyString && value.trim() === "") return true;
    return (
      phoneRegex.test(value) &&
      (value.startsWith("+44") ? value.length === 13 : value.length === 11)
    );
  };
  const passwordCheck = (value: string) => passwordRegex.test(value);
  const textCheck = (value: string, ignoreEmptyString: boolean) => {
    if (ignoreEmptyString && value.trim() === "") return true;
    return value.trim() !== "" && textRegex.test(value);
  };
  const alphanumericCheck = (value: string, ignoreEmptyString: boolean) => {
    if (ignoreEmptyString && value.trim() === "") return true;
    return value.trim() !== "" && alphanumericRegex.test(value);
  };
  const matchingFieldCheck = (value: string, compareTo: string) =>
    value === compareTo;

  const validateField = (
    rule: ValidationRule,
    value: string,
    fieldName: string,
    ignoreEmptyString: boolean = false,
    compareTo?: string | null,
    fieldDisplayName?: string | null,
  ) => {
    let isValid = false;
    let helperText = "";
    const displayName = fieldDisplayName ?? fieldName;

    switch (rule) {
      case "email":
        isValid = emailCheck(value);
        helperText = isValid ? "" : "Please enter a valid email.";
        break;
      case "phone":
        isValid = phoneCheck(value, ignoreEmptyString);
        helperText = isValid ? "" : "Please enter a valid phone number.";
        break;
      case "text":
        isValid = textCheck(value, ignoreEmptyString);
        helperText = isValid
          ? ""
          : `${displayName} cannot be empty and must contain only letters.`;
        break;
      case "alphanumeric":
        isValid = alphanumericCheck(value, ignoreEmptyString);
        helperText = isValid
          ? ""
          : `${displayName} cannot be empty and must contain only letters and numbers.`;
        break;
      case "password":
        isValid = passwordCheck(value);
        helperText = isValid
          ? ""
          : "Password must be at least 12 characters long and include uppercase, lowercase, numbers, and symbols.";
        break;
      case "matchingField":
        if (compareTo) {
          isValid = matchingFieldCheck(value, compareTo);
          helperText = isValid ? "" : `${displayName} does not match.`;
        }
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [fieldName]: {
        error: !isValid,
        helperText,
      },
    }));

    return isValid;
  };

  return {
    errors,
    validateField,
  };
};

export default useValidateInput;
