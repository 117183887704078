import React from "react";
import { FieldHelpProps, helpId } from "@rjsf/utils";
import { Typography } from "@mui/material";

const FieldHelpTemplate: React.FC<FieldHelpProps> = ({ help, idSchema }) => {
  const id = helpId(idSchema);
  return (
    <Typography variant="body1" color="text.secondary" id={id}>
      <i>{help}</i>
    </Typography>
  );
};

export default FieldHelpTemplate;
