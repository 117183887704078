import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import LendingIcon from "../../components/Icons/LendingIcon/LendingIcon";
import LenderOptionsBanner from "../../components/LenderOptionsBanner/LenderOptionsBanner";
import UserTopNavigation from "../../components/UserTopNavigation/UserTopNavigation";
import useFetchProducts from "../../hooks/api/useFetchProducts";
import { productColumns } from "./data/model";

const LendingOptionsScreen: React.FC = () => {
  // Tempory local state until we can control this via API
  // Same logic in User List

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 50,
  });
  const { rows, rowCount, loading, error } = useFetchProducts(
    paginationModel.page,
  );

  return (
    <Stack gap={4}>
      <UserTopNavigation />
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        flexDirection="row"
        sx={{ flexWrap: "wrap", justifyContent: "flex-start" }}
      >
        <LendingIcon />
        <Typography component="h1" variant="h1" color="black">
          Lending Options
        </Typography>
      </Box>
      <LenderOptionsBanner
        onAddLendingOption={() => console.debug("Add Lending Option Clicked")}
        onExploreTemplates={() => console.debug("Explore Templates Clicked")}
      />
      {error && (
        <Typography color="error">{error.response?.data.message}</Typography>
      )}
      <DataGrid
        rows={rows}
        columns={productColumns}
        rowCount={rowCount}
        loading={loading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[50, 100]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Stack>
  );
};

export default LendingOptionsScreen;
