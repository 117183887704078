import React, { ReactNode } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import UserTopNavigation from "../UserTopNavigation/UserTopNavigation";

interface SingleViewLayoutProps {
  children?: ReactNode;
  isLoading?: boolean;
  errors?: Array<string>;
}

const SingleViewLayout: React.FC<SingleViewLayoutProps> = ({
  children,
  isLoading = false,
  errors = [],
}) => {
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (errors.length > 0) {
    return (
      <>
        {errors.map((error, index) => (
          <Typography key={index} color="error">
            {error}
          </Typography>
        ))}
      </>
    );
  }

  return (
    <Stack spacing={4}>
      <UserTopNavigation />
      {children}
    </Stack>
  );
};

export default SingleViewLayout;
