import useAxios from "axios-hooks";

const useFetchCategories = () => {
  const [
    {
      data: categoriesData,
      loading: categoriesLoading,
      error: categoriesError,
    },
    fetchCategories,
  ] = useAxios(
    {
      url: "/api/v1/product-categories",
      method: "GET",
    },
    {
      useCache: false,
      manual: true,
    },
  );

  const handleFetchCategories = async () => {
    try {
      await fetchCategories();
    } catch (error) {
      console.error("Fetch request failed:", error);
      return { error };
    }
  };

  return {
    handleFetchCategories,
    categoriesData,
    categoriesLoading,
    categoriesError,
  };
};

export default useFetchCategories;
