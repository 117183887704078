import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconContainer from "./assets/IconContainer";
import ProvideLogoBackground from "./assets/ProvideLogoBackground";
import ProvideButton from "../Buttons/ProvideButton";
import PlainTextButton from "../Buttons/PlainTextButton";

export interface LenderOptionsBannerProps {
  onAddLendingOption: () => void;
  onExploreTemplates: () => void;
}

const LenderOptionsBanner: React.FC<LenderOptionsBannerProps> = ({
  onAddLendingOption,
  onExploreTemplates,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        p: { xs: 2, sm: 7 },
        backgroundColor: "boxBlue",
        borderRadius: 1,
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          display: { xs: "none", sm: "block" },
          top: 0,
          right: 0,
          height: "100%",
          opacity: 0.05,
        }}
      >
        <ProvideLogoBackground />
      </Box>

      <Stack gap={3} sx={{ flex: 1, zIndex: 1, mr: { xs: 0, sm: 4 } }}>
        <Typography
          variant="h1"
          color="black"
          sx={{ lineHeight: 0.85, fontSize: { xs: "2rem", sm: "3rem" } }}
        >
          Accurately match with your customers.
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
          Quickly <strong>add or upload</strong> criteria from your products to
          create bespoke <strong>Lending Options</strong> to match with only
          Borrowers that fit your ideal lending situation.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            alignItems: "center",
          }}
        >
          <ProvideButton
            onClick={onAddLendingOption}
            variant="contained"
            sx={{
              width: { xs: "100%", sm: 248 },
              height: 42,
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            ADD LENDING OPTION
          </ProvideButton>

          <PlainTextButton
            onClick={onExploreTemplates}
            sx={{
              width: { xs: "100%", sm: 248 },
              height: 42,
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            EXPLORE OUR TEMPLATES
          </PlainTextButton>
        </Box>
      </Stack>
      <Box
        sx={{
          display: { xs: "none", lg: "block" },
          alignSelf: "center",
          mr: 20,
        }}
      >
        <IconContainer />
      </Box>
    </Box>
  );
};

export default LenderOptionsBanner;
