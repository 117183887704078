import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { CompanyData, Team } from "./interface/interface";
import { hasStateChanged } from "./helpers/helpers";

interface CompanyViewState {
  companyViewSliceOption: string;
  hasChanges: boolean;
  isValidated: boolean;

  companyViewData: CompanyData | null;
  originalCompanyViewData: CompanyData | null;

  companyViewTeamsData: Team[] | null;
  originalCompanyViewTeamsData: Team[] | null;
}

const initialState: CompanyViewState = {
  companyViewSliceOption: "main",
  hasChanges: false,
  isValidated: false,

  companyViewData: null,
  originalCompanyViewData: null,

  companyViewTeamsData: null,
  originalCompanyViewTeamsData: null,
};

const companyViewSlice = createSlice({
  name: "companyView",
  initialState,
  reducers: {
    setCompanyViewOption: (state, action: PayloadAction<string>) => {
      state.companyViewSliceOption = action.payload;
    },

    setCompanyViewData: (state, action: PayloadAction<CompanyData>) => {
      state.companyViewData = { ...state.companyViewData, ...action.payload };
      if (!state.originalCompanyViewData) {
        state.originalCompanyViewData = action.payload;
      }
      state.hasChanges = hasStateChanged(
        state.originalCompanyViewData,
        state.companyViewData,
      );
    },

    setCompanyViewTeamsData: (state, action: PayloadAction<Team[]>) => {
      state.companyViewTeamsData = action.payload;
      if (!state.originalCompanyViewTeamsData) {
        state.originalCompanyViewTeamsData = action.payload;
      }
      state.hasChanges = hasStateChanged(
        state.originalCompanyViewTeamsData,
        state.companyViewTeamsData,
      );
    },

    resetCompanyViewChanges: (state) => {
      state.companyViewData = state.originalCompanyViewData;
      state.companyViewTeamsData = state.originalCompanyViewTeamsData;
      state.hasChanges = false;
    },

    applyCompanyViewChanges: (state) => {
      state.originalCompanyViewData = state.companyViewData;
      state.originalCompanyViewTeamsData = state.companyViewTeamsData;
      state.hasChanges = false;
    },

    setIsValidated: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
  },
});

export const checkUrlForCompanyOption = (): AppThunk => (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search);
  const option = searchParams.get("option");

  if (option === "main") {
    dispatch(setCompanyViewOption(option));
  }
};

export const {
  setCompanyViewOption,
  setCompanyViewData,
  setCompanyViewTeamsData,
  resetCompanyViewChanges,
  applyCompanyViewChanges,
  setIsValidated,
} = companyViewSlice.actions;

export default companyViewSlice.reducer;
