// React and Core Libraries
import React from "react";

// Material-UI Components
import { Stack } from "@mui/material";

// Custom Components
import UserTeams from "../UserTeams/UserTeams";

// Hooks
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";

// Redux Store and Actions
import { RootState } from "../../store/store";
import { setUserViewData } from "../../store/slices/userViewSlice";

const UserSingleViewTeams = () => {
  const dispatch = useAppDispatch();

  // Redux State Selectors
  const userDataRedux = useAppSelector(
    (state: RootState) => state.userView.userViewData,
  );
  const userTeamsRedux = useAppSelector(
    (state: RootState) => state.userView.userViewTeamsData,
  );
  const userCompanyRedux = useAppSelector(
    (state: RootState) => state.userView.userViewCompanyData,
  );

  // Permissions for Teams Editing
  const teamsPermissions = useAppSelector((state: RootState) =>
    state.permissionsList.permissions.includes("update-all-teams"),
  );

  // Handle Selection Changes
  const handleSelectionChange = (updatedIds: string[]) => {
    dispatch(
      setUserViewData({
        teams: updatedIds,
      }),
    );
  };

  return (
    <Stack spacing={4} sx={{ maxWidth: 480 }}>
      <Stack spacing={4} sx={{ minHeight: 140 }}>
        <UserTeams
          teamsData={userTeamsRedux || []}
          teamsTitle={userCompanyRedux?.name}
          teamsLogo={userCompanyRedux?.logo}
          editable={teamsPermissions}
          selectedIds={userDataRedux?.teams ?? []}
          onSelectionChange={handleSelectionChange}
        />
      </Stack>
    </Stack>
  );
};

export default UserSingleViewTeams;
