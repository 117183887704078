import React, { FC } from "react";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";

interface RootScreenProps {
  children: React.ReactNode;
  contained?: boolean;
}

const RootAuthScreen: FC<RootScreenProps> = ({
  children,
  contained = false,
}) => {
  const drawerOpen = useAppSelector(
    (state: RootState) => state.navigation.drawerOpen,
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { menuOpenWidth, menuClosedWidth } = theme.custom.contentWidth;
  const menuWidth = drawerOpen ? menuOpenWidth : menuClosedWidth;
  const paddingLeft = isSmallScreen ? 0 : menuWidth;
  const maxWidth = contained ? "lg" : false;

  return (
    <Container disableGutters={true} maxWidth={maxWidth}>
      <Box sx={{ mx: 4, py: 2, pl: paddingLeft }}>{children}</Box>
    </Container>
  );
};

export default RootAuthScreen;
