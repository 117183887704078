import useAxios from "axios-hooks";

const useFetchProducts = (page: number) => {
  const [{ data, loading, error }] = useAxios({
    url: "/api/v1/products",
    method: "GET",
    params: {
      page: page + 1,
    },
  });

  return {
    rows: data?.data || [],
    rowCount: data?.meta?.total || 0,
    loading,
    error,
  };
};

export default useFetchProducts;
