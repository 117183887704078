import React, { useEffect, useRef } from "react";
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { Stack, Box, Typography } from "@mui/material";
import { sanitizeTitle } from "../../../utils/helper";

const CardSectionTemplate: React.FC<ObjectFieldTemplateProps> = ({
  title,
  properties,
  formContext,
}) => {
  const sanitizedId = sanitizeTitle(title);
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (formContext.setSectionRef && sectionRef.current) {
      formContext.setSectionRef(sanitizedId)(sectionRef.current);
    }
  }, [formContext, sanitizedId]);

  if (properties.length === 0) {
    return null;
  }

  return (
    <Box id={sanitizedId} sx={{ mb: 7 }} ref={sectionRef}>
      <Typography variant="h1" sx={{ mb: 3, fontSize: "2rem", color: "black" }}>
        {title}
      </Typography>
      <Stack spacing={7} sx={{ ml: 2 }}>
        {properties.map((p, index) => (
          <div key={`${p.name}_${index}`}>{p.content}</div>
        ))}
      </Stack>
    </Box>
  );
};

export default CardSectionTemplate;
