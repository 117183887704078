import React from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

const CustomCheckboxesWidget: React.FC<WidgetProps> = ({
  id,
  value = [],
  onChange,
  options,
  uiSchema,
}) => {
  const { enumOptions } = options;
  const title = uiSchema?.["ui:title"];

  const handleChange = (optionValue: string) => {
    const newValue = value.includes(optionValue)
      ? value.filter((v: string) => v !== optionValue)
      : [...value, optionValue];

    onChange(newValue);
  };

  return (
    <>
      {title && (
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {title}
        </Typography>
      )}
      <FormGroup id={id} sx={{ ml: 1 }}>
        {enumOptions?.map((option) => (
          <FormControlLabel
            key={option.value}
            sx={{ mb: 1 }}
            control={
              <Checkbox
                checked={value.includes(option.value)}
                onChange={() => handleChange(option.value)}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default CustomCheckboxesWidget;
