import React, { useCallback, useState } from "react";
import { FieldProps } from "@rjsf/utils";
import Box from "@mui/material/Box";
import { getDefaultRegistry } from "@rjsf/core";
import RangeSlider from "../../../components/RangeSlider/RangeSlider";

const {
  fields: { ObjectField: DefaultObjectField },
} = getDefaultRegistry();

function CustomObjectField(props: FieldProps) {
  const { formData, schema, onChange, uiSchema } = props;

  const title = uiSchema?.["ui:title"];
  const minimumRange = uiSchema?.["ui:minimumRange"];
  const maximumRange = uiSchema?.["ui:maximumRange"];

  const hasRangeValues =
    schema.properties?.minimumValue && schema.properties?.maximumValue;

  if (!hasRangeValues) {
    return (
      <Box>
        <DefaultObjectField {...props} />
      </Box>
    );
  }

  const [sliderValue, setSliderValue] = useState<[number, number]>([
    formData?.minimumValue ?? minimumRange,
    formData?.maximumValue ?? maximumRange,
  ]);

  const handleSliderChange = useCallback(
    (_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
      if (Array.isArray(newValue)) {
        setSliderValue(newValue as [number, number]);
      }
    },
    [],
  );

  const handleSliderCommit = useCallback(
    (_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
      if (Array.isArray(newValue)) {
        onChange({
          minimumValue: newValue[0],
          maximumValue: newValue[1],
        });
      }
    },
    [],
  );

  const handleInputChange =
    (key: "minimumValue" | "maximumValue") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(event.target.value);
      const updatedValues =
        key === "minimumValue"
          ? [newValue, sliderValue[1]]
          : [sliderValue[0], newValue];

      setSliderValue(updatedValues as [number, number]);
      onChange({ ...formData, [key]: newValue });
    };

  return (
    <RangeSlider
      title={title}
      sliderValue={sliderValue}
      min={minimumRange}
      max={maximumRange}
      onSliderChange={handleSliderChange}
      onSliderCommit={handleSliderCommit}
      onInputChange={handleInputChange}
    />
  );
}

export default CustomObjectField;
