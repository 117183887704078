import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { UserData } from "./interface/interface";

interface SettingsState {
  settingsOption: string;
  userData: UserData | null;
}

const initialState: SettingsState = {
  settingsOption: "main",
  userData: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettingsOption: (state, action: PayloadAction<string>) => {
      state.settingsOption = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
  },
});

export const checkUrlForSettingsOption = (): AppThunk => (dispatch) => {
  const searchParams = new URLSearchParams(window.location.search);
  const option = searchParams.get("option");

  if (option === "main" || option === "teams" || option === "notifications") {
    dispatch(setSettingsOption(option));
  }
};

export const { setSettingsOption, setUserData } = settingsSlice.actions;
export default settingsSlice.reducer;
