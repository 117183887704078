import React from "react";
import { Box, Typography, Stack, Avatar, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Pointer from "../Pointer/Pointer";
import RoundedBoxContainer from "../RoundedBoxContainer/RoundedBoxContainer";
import CustomStepperProgressBar from "./CustomStepperProgressBar";

interface StepperProps {
  title: string;
  subTitle?: string;
  steps: string[];
  currentStep: number;
  requiresValidation?: boolean;
}

const CustomStepperDesktop: React.FC<StepperProps> = ({
  title,
  subTitle,
  steps,
  currentStep,
  requiresValidation = false,
}) => {
  return (
    <RoundedBoxContainer>
      <Typography variant="h4" fontSize="1.2rem">
        {title}
      </Typography>
      <Stack gap={1}>
        {subTitle ? <Typography variant="body1">{subTitle}</Typography> : null}
        {requiresValidation ? (
          <CustomStepperProgressBar steps={steps} currentStep={currentStep} />
        ) : null}
      </Stack>
      <Stack>
        {steps.map((step, index) => {
          const isCompleted = index < currentStep;
          const isCurrent = index === currentStep;

          const bgColourCompleted = requiresValidation
            ? "gray.dark"
            : "lightGreen";
          const bgColour = isCurrent ? "black" : "gray.dark";

          return (
            <Box key={index}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: isCompleted ? bgColourCompleted : bgColour,
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  {isCompleted && !requiresValidation ? (
                    <CheckIcon sx={{ fontSize: "1rem" }} />
                  ) : (
                    <Typography>{index + 1}</Typography>
                  )}
                </Avatar>

                <Typography
                  variant="h4"
                  fontSize="0.9rem"
                  sx={{
                    color: bgColour,
                  }}
                >
                  {step}
                </Typography>
              </Stack>

              {isCurrent && (
                <Pointer
                  icon={<ArrowBackIcon />}
                  label="You are here!"
                  sx={{
                    position: "absolute",
                    right: "-85px",
                    transform: "translateY(-95%)",
                  }}
                />
              )}
              {index < steps.length - 1 && (
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: "gray.light",
                    mx: 1.4,
                    my: 1,
                    height: 14,
                    width: "1px",
                  }}
                />
              )}
            </Box>
          );
        })}
      </Stack>
    </RoundedBoxContainer>
  );
};

export default CustomStepperDesktop;
