import useAxios from "axios-hooks";
import { EditUserPayload } from "../../store/slices/interface/interface";

const useUser = () => {
  const [
    { loading: fetchingUser, error: fetchUserError, data: userData },
    getUser,
  ] = useAxios({ method: "GET" }, { manual: true, useCache: false });

  const [{ loading: editing, error: editError, data: editData }, editUser] =
    useAxios({ method: "PUT" }, { manual: true });

  const handleGetUser = async (id: string) => {
    if (!id) return;

    try {
      await getUser({ url: `/api/v1/users/${id}` });
    } catch (error) {
      console.error("Fetch user request failed:", error);
      return { error };
    }
  };

  const handleEditUser = async (id: string, payload: EditUserPayload) => {
    if (!id) return;

    try {
      await editUser({
        url: `/api/v1/users/${id}`,
        data: {
          first_name: payload.firstName,
          last_name: payload.lastName,
          date_of_birth: payload.dob,
          phone_number: payload.phone,
          job_title: payload.jobTitle,
          roles: payload.roles,
          teams: payload.teams,
          status: payload.status,
          ...(payload.newPassword && { password: payload.newPassword }),
          ...(payload.currentPassword && {
            current_password: payload.currentPassword,
          }),
        },
      });
    } catch (error) {
      console.error("Edit user request failed:", error);
      return { error };
    }
  };

  return {
    handleGetUser,
    userData,
    fetchingUser,
    fetchUserError,
    handleEditUser,
    editing,
    editError,
    editData,
  };
};

export default useUser;
