import React from "react";
import { Box } from "@mui/material";

interface CustomStepperProgressBarProps {
  steps: string[];
  currentStep: number;
}

const CustomStepperProgressBar: React.FC<CustomStepperProgressBarProps> = ({
  steps,
  currentStep,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", width: "100%" }}>
        {steps.map((_, index) => {
          const sectionWidth = 100 / steps.length;
          const isCompleted = index < currentStep;

          return (
            <Box
              key={index}
              sx={{
                flex: `${sectionWidth}%`,
                height: 8,
                backgroundColor: isCompleted ? "primary.main" : "gray.light",
                position: "relative",
                borderRadius: `${index === 0 ? "4px 0 0 4px" : ""} ${index === steps.length - 1 ? "0 4px 4px 0" : ""}`,
                transition: "background-color 0.1s linear",
              }}
            >
              {index < steps.length - 1 && (
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "1px",
                    height: "100%",
                    backgroundColor: "white",
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomStepperProgressBar;
