import React, { ReactNode } from "react";
import { Stack } from "@mui/material";

interface CustomBoxProps {
  children: ReactNode;
  spacing?: number;
  maxWidth?: number | string;
}

const RoundedBoxContainer: React.FC<CustomBoxProps> = ({
  children,
  spacing = 3,
  maxWidth = 350,
}) => {
  return (
    <Stack
      spacing={spacing}
      maxWidth={maxWidth}
      sx={{
        p: 3,
        boxSizing: "border-box",
        borderRadius: 1,
        border: "1px solid",
        borderColor: "gray.light",
        backgroundColor: "white",
      }}
    >
      {children}
    </Stack>
  );
};

export default RoundedBoxContainer;
