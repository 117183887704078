import React from "react";
import { ObjectFieldTemplateProps } from "@rjsf/utils";
import SectionsContainer from "./SectionsContainer";
import { useSchemaFormTemplate } from "../../../hooks/useSchemaFormTemplate";

const DefaultObjectFieldTemplate = useSchemaFormTemplate("ObjectFieldTemplate");

const ObjectFieldTemplate: React.FC<ObjectFieldTemplateProps> = (props) => {
  const { formContext, uiSchema, idSchema } = props;
  const SectionTemplate = formContext["sectionTemplate"];

  if (!SectionTemplate || !uiSchema || idSchema.$id !== "root") {
    return <DefaultObjectFieldTemplate {...props} />;
  }

  return <SectionsContainer {...props} />;
};

export default ObjectFieldTemplate;
