import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface CardWrapperProps {
  children: ReactNode;
  maxWidth?: string;
}

const CardWrapper: React.FC<CardWrapperProps> = ({ children, maxWidth }) => {
  return (
    <Box
      sx={{
        maxWidth: maxWidth,
        boxSizing: "border-box",
        backgroundColor: "boxGray",
        borderRadius: "28px",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {children}
    </Box>
  );
};

export default CardWrapper;
